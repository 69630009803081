import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/Auth';
import KarteraLogo from '../../assets/KarteraLogoBlack.svg';
import { useApi } from '../../services/api/hooks';
import {
  emailIncludesPlusSign,
  isEmailDomainAllowed,
  isEmailValid,
} from '../../utils/handleValuesHelper';
import { OtpRequestDTO } from '../../dtos/auth/otpRequestDTO';
import { OtpLoginRequestDTO } from '../../dtos/auth/otpLoginRequestDTO';
import { color } from '../../consts/ColorConst';
import { KarteraButton } from '../../components/kartera/button';
import { KarteraProgress } from '../../components/kartera/progress';
import { KarteraOtp } from '../../components/kartera/otp';
import { KarteraTextField } from '../../components/kartera/textField';
import { TotpLoginRequestDTO } from '../../dtos/auth/totpLoginRequestDTO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldKeyhole } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: color.GREY_DARK_4,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginContainer: {
    width: 400,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '54px 32px 32px',
    gap: 18,
  },
  loginHeader: {
    fontSize: 26,
    fontWeight: 700,
  },
  loginDescription: {
    fontSize: 14,
    fontWeigth: 400,
    letterSpacing: 0.2,
    lineHeight: 1.2,
  },
  loginForm: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 8,
    gap: 8,
  },
  forgetButtonWrapper: {
    width: '200px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-8px',
  },
  forgetButton: {
    cursor: 'pointer',
    color: color.GREEN_DARK_1,
    fontSize: 10,
  },
  loadingWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px 0',
  },
  loginButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '2px 0 24px',
  },
  loginButton: {
    width: 130,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
  },
  registerContainer: {
    display: 'flex',
  },
  registerDescription: {
    fontSize: 14,
    color: color.GREY_DARK_1,
  },
  registerButton: {
    cursor: 'pointer',
    color: color.GREEN_DARK_1,
    margin: '0 0 0 6px',
    fontSize: 14,
  },
  errorMessage: {
    color: color.RED,
    fontSize: 13,
    textAlign: 'center',
  },
}));

type Props = {
  updatedPassword?: boolean;
};

function Login({ updatedPassword }: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const { postAuthLogin, postAuthOtpLogin, postAuthTotpLogin } = useApi();
  const { t } = useTranslation('translation', { keyPrefix: 'login' });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessages, setErrorMessages] = useState({
    email: '',
    password: '',
  });
  const [hasError, setHasError] = useState(false);

  const [otpOpen, setOtpOpen] = useState(false);
  const [otpToken, setOtpToken] = useState('');
  const [otpTotalSeconds, setOtpTotalSeconds] = useState(300);
  const [otpLoadingValue, setOtpLoadingValue] = useState(40);
  const [otpFailed, setOtpFailed] = useState(false);

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const [loadingValue, setLoadingValue] = useState(40);
  const [requestError, setRequestError] = useState('');
  const [totpEnabled, setTotpEnabled] = useState(false);

  document.title = 'Kartera - Login';

  useEffect(() => {
    setHasError(!!Object.values(errorMessages).find((msg) => msg !== ''));
  }, [errorMessages]);

  const onEmailChange = useCallback(
    (event) => {
      if (loadingOpen) setLoadingOpen(false);
      setEmail(event.target.value);
    },
    [setEmail, setLoadingOpen, loadingOpen],
  );

  const onPasswordChange = useCallback(
    (event) => {
      if (loadingOpen) setLoadingOpen(false);
      setPassword(event.target.value);
    },
    [setPassword, setLoadingOpen, loadingOpen],
  );

  const handleSubmitLogin = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        setRequestFailed(false);
        setLoadingOpen(true);
        setRequestError('');

        const dataLogin = {
          email: email,
          password: password,
          type: 'LOGIN',
        };

        const respLogin = await postAuthLogin(dataLogin, (error: any) => {
          if (error.message.includes('blocked')) {
            setRequestError(t('errorMsgAccountBlocked'));
          } else if (error.message.includes('(attempts left:')) {
            const attemptsCount = String(error.message).charAt(error.message.length - 2);
            setRequestError(t('errorMsgIncorrectEmailOrPassword', { attempts: attemptsCount }));
          } else {
            setRequestError(error.message);
          }
        });

        setOtpToken(respLogin.otp_token);
        setTotpEnabled(respLogin.totp_enabled);
        setLoadingValue(100);
        setTimeout(() => {
          setLoadingOpen(false);
          setOtpOpen(true);
        }, 1000);
      } catch (error) {
        setLoadingValue(100);
        setRequestFailed(true);
        setTimeout(() => {
          setLoadingOpen(false);
        }, 1000);
      }
    },
    [setOtpOpen, setLoadingOpen, setOtpToken, email, password, setRequestFailed],
  );

  const onForgetClick = useCallback(() => {
    navigate('/reset-password');
  }, [navigate]);

  const onRegisterClick = useCallback(() => {
    navigate('/register');
  }, [navigate]);

  
  const onPinCodeComplete = useCallback(
    async (value: any) => {
      try {
        setOtpFailed(false);
        const data: OtpLoginRequestDTO = {
          type: 'LOGIN',
          otp_token: otpToken,
          one_time_password: value,
        };
        const dataTotp: TotpLoginRequestDTO = {
          otp_token: otpToken,
          totp_code: value,
        };
        const authData = totpEnabled
          ? await postAuthTotpLogin(dataTotp)
          : await postAuthOtpLogin(data);
        if (authData) {
          setOtpLoadingValue(100);
          setTimeout(() => signIn({ ...authData, origin: 'LOGIN' }), 1000);
        } else {
          setOtpLoadingValue(100);
          setOtpFailed(true);
        }
      } catch (error) {
        setOtpLoadingValue(100);
        setOtpFailed(true);
      }
    },
    [otpToken, setLoadingOpen, totpEnabled],
  );

  function emailValidation() {
    if (email === '') return;
    let errorMessage = '';
    if (!isEmailValid(email)) errorMessage = t('errorMsgInvalidEmail');
    if (!isEmailDomainAllowed(email)) errorMessage = t('errorMsgWorkEmail');
    if (emailIncludesPlusSign(email)) errorMessage = t('errorMsgEmailPlusSign');
    setErrorMessages((prev) => ({ ...prev, email: errorMessage }));
  }

  function passwordValidation() {
    if (password === '') {
      setErrorMessages((prev) => ({ ...prev, password: t('errorMsgPassword') }));
    } else {
      setErrorMessages((prev) => ({ ...prev, password: '' }));
    }
  }

  const onRequestClick = useCallback(async () => {
    try {
      setOtpFailed(false);
      setOtpTotalSeconds(300);
      setOtpOpen(true);
    } catch (error) {
      setLoadingOpen(false);
    }
  }, [email, otpToken]);

  const buttonDisabled = hasError || !email || !password;

  return (
    <Box data-testid='login-page-container' className={classes.root}>
      {otpOpen ? (
        <Box>
          <KarteraOtp
            type='signin'
            title={t('otptitle')}
            description={totpEnabled ? t('totpDescription') : t('otpDescription')}
            totalSeconds={otpTotalSeconds}
            onPinCodeComplete={(value: any) => onPinCodeComplete(value)}
            requestClick={() => onRequestClick()}
            progressValue={otpLoadingValue}
            error={otpFailed}
            twoFAEnabled={totpEnabled}
          />
        </Box>
      ) : (
        <form className={classes.loginContainer} onSubmit={handleSubmitLogin}>
          {!updatedPassword && <img src={KarteraLogo} alt='Kartera logo' width={70} />}
          <Typography className={classes.loginHeader}>
            {updatedPassword ? (
              <>
                <FontAwesomeIcon style={{ marginRight: 8 }} fontSize={22} icon={faShieldKeyhole} />
                {t('passwordUpdated')}
              </>
            ) : (
              t('loginHeader')
            )}
          </Typography>
          <Typography className={classes.loginDescription}>
            {updatedPassword ? t('passwordUpdatedMessage') : t('loginDescription')}
          </Typography>
          <Box className={classes.loginForm}>
            <KarteraTextField
              value={email}
              spacious
              placeholder={t('emailPlaceholder')}
              errorMessage={errorMessages.email}
              required
              onChange={onEmailChange}
              onCancel={() => setEmail('')}
              onBlur={() => emailValidation()}
              type='email'
              fullWidth
            />
            <KarteraTextField
              value={password}
              spacious
              placeholder={t('passwordPlaceholder')}
              errorMessage={errorMessages.password}
              required
              onChange={onPasswordChange}
              onBlur={() => passwordValidation()}
              type='password'
              sx={{ marginBottom: 4 }}
              fullWidth
            />
            <Box className={classes.forgetButtonWrapper}>
              <Box className={classes.forgetButton} onClick={onForgetClick}>
                {t('forgetButton')}
              </Box>
            </Box>
          </Box>
          {loadingOpen ? (
            <Box className={classes.loadingWrapper}>
              <KarteraProgress value={loadingValue} />
            </Box>
          ) : (
            <>
              {requestFailed && <pre className={classes.errorMessage}>{requestError}</pre>}
              <Box className={classes.loginButtonWrapper}>
                <KarteraButton
                  text={t('loginButton')}
                  disabled={buttonDisabled}
                  className={classes.loginButton}
                  spacious
                  onClick={handleSubmitLogin as any}
                />
              </Box>
              <Box className={classes.registerContainer}>
                <Typography className={classes.registerDescription}>
                  {t('registerDescription')}{' '}
                </Typography>
                <Typography className={classes.registerButton} onClick={onRegisterClick}>
                  {t('registerButton')}
                </Typography>
              </Box>
            </>
          )}
        </form>
      )}
    </Box>
  );
}

export default Login;
