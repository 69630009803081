import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { faDownload, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { color } from '../../../consts/ColorConst';
import { KarteraButton } from '../button';
import { KarteraTooltip } from '../tooltip';
import { currencyFormatter, numberFormatter } from '../../../utils/handleValuesHelper';
import { handleRangeLabel, truncateText } from '../../../utils/helperFunctions';
import { Props } from './types';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '260px',
    height: '270px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: color.WHITE_01,
    boxShadow: theme.shadow.elevation4Grey,
    borderRadius: 6,
    position: 'relative',
  },
  range: {
    fontSize: 12,
    fontWeight: 400,
    color: color.GREY_LIGHT_2,
  },
  topContent: {
    display: 'flex',
    padding: '12px 16px 8px',
  },
  topLeftContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: color.GREY_DARK_4,
  },
  totalWrapper: {
    display: 'flex',
    height: 42,
    alignItems: 'center',
    gap: 6,
  },
  total: {
    fontSize: 36,
    fontWeight: 600,
    color: color.GREY_DARK_2,
  },
  monetary: {
    fontSize: 20,
    fontWeight: 500,
    color: color.GREY_LIGHT_2,
    paddingTop: 10,
  },
  symbol: {
    fontSize: 24,
    fontWeight: 500,
    color: color.GREY_LIGHT_2,
    paddingTop: 6,
  },
  points: {
    fontSize: 17,
    fontWeight: 500,
    color: color.GREY_LIGHT_2,
    paddingTop: 6,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: '14px 16px 0',
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: color.GREY_DARK_4,
    '& label': {
      fontSize: 14,
      fontWeight: 400,
      color: color.GREY_LIGHT_1,
    },
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  disabledBox: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    top: 64,
    left: 0,
    height: 200,
    width: '100%',
    '& pre': {
      fontFamily: 'Roboto',
      fontSize: 14,
      fontStyle: 'italic',
      fontWeight: 400,
      color: color.GREY,
      textAlign: 'center',
    },
    backgroundColor: 'rgba(255,255,255,0.6)',
    zIndex: 1,
  },
  disabled: {
    filter: 'blur(7px)',
    '-webkit-filter': 'blur(7px)',
  },
  reportButton: {
    width: 'max-content',
    float: 'left',
  },
  customerContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0px 8px',
    margin: '0 4px 0 8px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      margin: '6px 3px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: color.GREY_LIGHT_2,
      borderRight: `2px solid ${color.GREY_LIGHT_5}`,
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        borderRight: `2px solid ${color.GREY_LIGHT_4}`,
      },
    },
  },
  customerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '4px 0',
    borderBottom: `1px solid ${color.GREY_LIGHT_4}`,
  },
  customerName: {
    fontSize: 14,
    fontWeight: 500,
    color: color.GREY_DARK_2,
    marginBottom: -4,
  },
  customerProduct: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY,
  },
  customerValue: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_LIGHT_1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 16px 14px',
  },
}));

export function KarteraReportCard({
  variant,
  range,
  isDisabled,
  customers,
  firstValue,
  secondValue,
  disableCurrencyFormat,
  isPointSystem,
  earnedPoints = '0',
  redeemedPoints = '0',
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });

  const cardDetails = {
    title:
      variant === 'BALANCE'
        ? t('balance')
        : variant === 'REVENUE'
        ? t('revenue')
        : variant === 'REWARDS'
        ? t('rewards')
        : t('topCustomers'),
    firstText:
      variant === 'BALANCE'
        ? t('totalBalance')
        : variant === 'REVENUE'
        ? t('totalRevenueGenerated')
        : variant === 'REWARDS'
        ? t('totalEarned')
        : '',
    secondText:
      variant === 'BALANCE'
        ? t('availableBalance')
        : variant === 'REVENUE'
        ? t('convertedFromBreakage')
        : variant === 'REWARDS'
        ? t('totalUsed')
        : '',
    hint:
      variant === 'BALANCE'
        ? t('balanceHint')
        : variant === 'REVENUE'
        ? t('revenueHint')
        : variant === 'REWARDS' && !isPointSystem
        ? t('rewardsHint')
        : variant === 'REWARDS' && isPointSystem
        ? t('rewardsPointsHint')
        : t('topCustomersHint'),
  };

  return (
    <Box className={`${classes.root}`}>
      <Box className={classes.topContent}>
        <Box className={classes.topLeftContent}>
          <Typography className={classes.title}>{cardDetails.title}</Typography>
          <Typography className={classes.range}>{handleRangeLabel(range)}</Typography>
        </Box>
        <KarteraTooltip title={cardDetails.hint} placement='right'>
          <FontAwesomeIcon
            size='sm'
            icon={faQuestionCircle}
            color={color.GREY_DARK_1}
            style={{ marginTop: 8 }}
          />
        </KarteraTooltip>
      </Box>
      {customers ? (
        <Box className={`${classes.customerContent} ${isDisabled && classes.disabled}`}>
          {customers.map((customer, idx) => (
            <Box key={`customer-${idx}`} className={classes.customerWrapper}>
              <Box>
                <Typography className={classes.customerName}>{customer.name}</Typography>
                <Typography className={classes.customerProduct}>
                  {truncateText(customer.product, 23)}
                </Typography>
              </Box>
              <Typography className={classes.customerValue}>
                {currencyFormatter(customer.value)}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box className={`${classes.content} ${isDisabled && classes.disabled}`}>
          <Box className={classes.fieldWrapper}>
            <label>{cardDetails.firstText}</label>
            {firstValue && (
              <Box className={classes.totalWrapper}>
                <Typography className={classes.monetary}>$</Typography>
                <Typography className={classes.total}>
                  {disableCurrencyFormat
                    ? +firstValue > 0
                      ? Number(firstValue).toFixed(2)
                      : 0
                    : numberFormatter(parseInt(firstValue)).split(' ')[0]}
                </Typography>
                {!disableCurrencyFormat && (
                  <Typography className={classes.symbol}>
                    {numberFormatter(parseInt(firstValue)).split(' ')[1]}
                  </Typography>
                )}
                {isPointSystem && (
                  <Typography className={classes.points}>
                    {`(${numberFormatter(parseInt(earnedPoints))} pts)`}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          <Box className={classes.fieldWrapper}>
            <label>{cardDetails.secondText}</label>
            {secondValue && (
              <Box className={classes.totalWrapper}>
                <Typography className={classes.monetary}>$</Typography>
                <Typography className={classes.total}>
                  {disableCurrencyFormat
                    ? +secondValue > 0
                      ? Number(secondValue).toFixed(2)
                      : 0
                    : numberFormatter(parseInt(secondValue)).split(' ')[0]}
                </Typography>
                {!disableCurrencyFormat && (
                  <Typography className={classes.symbol}>
                    {numberFormatter(parseInt(secondValue)).split(' ')[1]}
                  </Typography>
                )}
                {isPointSystem && (
                  <Typography className={classes.points}>
                    {`(${numberFormatter(parseInt(redeemedPoints))} pts)`}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box className={`${classes.footer} ${isDisabled && classes.disabled}`}>
        <KarteraButton
          className={classes.reportButton}
          variant='secondary'
          icon={<FontAwesomeIcon icon={faDownload} />}
          text={t('report')}
          disabled={isDisabled}
        />
      </Box>
      {isDisabled && (
        <Box className={classes.disabledBox}>
          <pre>{t('disabledText')}</pre>
        </Box>
      )}
    </Box>
  );
}
