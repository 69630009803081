import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { color } from '../../consts/ColorConst';

const useStyles = makeStyles((theme: any) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: color.GRAY_01,
    padding: '35px 40px',
  },
  contentContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  platformContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 32,
    marginBottom: 32,
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
    padding: '0 0 31px',
  },
}));

export function ThirdParty() {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'thirdParty' });

  document.title = 'Kartera - 3rdParty';

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>{t('title')}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
