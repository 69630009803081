import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    resources: {
      en: {
        translation: {
          components: {
            dangerousContentError: 'This field cannot contain  \\  / <  >  (  )  *  ?  ;  : ',
          },
          navbar: {
            logout: 'Logout',
            fixedMessage1: 'Want some help getting started?',
            fixedMessage2: 'Book a 1:1 with Kartera',
            documentationButton: 'documentation',
            optionSandbox: 'Sandbox',
            optionProduction: 'Production',
            menuSettings: 'Settings',
            menuHelp: 'Help',
            menuStaffAdmin: 'Staff Admin Portal',
            menuLogout: 'Logout',
            mockDataButton: 'Populate mock data',
            userListLink: 'Users',
          },
          resetPassword: {
            pageTitle: 'Forgot Your Password?',
            description: `No worries! Enter your email and we will send you instructions how to reset your password:`,
            emailPlaceholder: 'Email',
            continueButtonText: 'Reset Password',
            backButtonText: 'Back',
            resendButtonText: 'Resend email',
            message: `If an account exists with your email address, {{ email }}, we just sent you an email with instructions to reset your password`,
            errorMsgInvalidEmail: 'Enter valid email address',
            errorMsgWorkEmail: 'Please provide a work email',
            errorMsgEmailPlusSign: 'You cannot use + in your email address',
            checkTitle: 'Check your inbox!',
            checkDescription:
              'We have sent the instructions how to reset your password to {{ email }} ',
            resendText: 'You did not receive the email?',
            resendButton: 'Resend',
            resendTitle: 'Instructions sent again!',
            resendMessage: `Wait up to 5 minutes to see if you receive the instructions sent to your email address {{ email }}.`,
            resendMessage2: `If not, reach out to us via `,
          },
          sandboxOnboarding: {
            incorporationTitle: 'Incorporation Details',
            businessNameLabel: 'Legal Business Name',
            businessNamePlaceholder: 'eg. Rize Financial Technologies Inc.',
            businessNameNote: 'As it appears in your incorporation documents (eg. IRS forms)',
            submitButton: 'Submit',
          },
          changePassword: {
            pageTitle: 'Set your password',
            description: `In order to protect your account, make sure your password contains:`,
            password: 'Password',
            confirmPassword: 'Confirm Password',
            ButtonText: 'Set Password',
            resendButtonText: 'Resend email',
            backButtonText: 'Login',
            successText: 'Password has been successfully changed.',
            errorMsgConfirmPassword: 'Passwords do not match.',
            character: 'Minimum 8 characters',
            upperCase: 'Minimum 1 uppercase letter',
            symbol: 'Minimum 1 symbol',
            number: 'Minimum 1 number ',
            errorMinChar: 'Must be min. 8 characters long. ',
            errorUppercase: 'Must contain min. 1 uppercase letter. ',
            errorSymbol: 'Must contain min. 1 symbol. ',
            errorNumber: 'Must contain min. 1 number. ',
            linkExpired: 'Link expired or invalid',
            linkExpiredDescription:
              'The link we have sent you to reset your password has expired or is invalid. Request a new one to reset your password.',
            requestNewLink: 'Request New Link',
          },
          register: {
            pageTitle: 'Create a Kartera Account',
            registerHeader: 'Welcome to Kartera!',
            formTitle: 'Welcome to Kartera!',
            formSubtitle: "Welcome to Kartera - let's create your account.",
            formDescription:
              'Welcome! Please enter your information below to register for Kartera.',
            approvalDescription:
              'For the approval of your account you need to be a <strong>Director</strong> with at least <strong>25% ownership</strong> of the company.',
            firstNameLabel: 'Legal First Name',
            lastNameLabel: 'Legal Last Name',
            emailLabel: 'Work Email',
            password: 'Password',
            confirmPassword: 'Confirm Password',
            loginButton: 'Login',
            loginText: 'Already have an account?',
            firstNamePlaceholder: 'First Name',
            lastNamePlaceholder: 'Last Name',
            emailPlaceholder: 'Email',
            passwordPlaceholder: 'Password',
            repeatPlaceholder: 'Repeat Password',
            nextStep: 'Next Step',
            registerButton: 'Register',
            otpDescriptionTitle: 'Verification code',
            otpDescription: `We have sent you an OTP code to your email {{email}} to verify your identity before you can continue.`,
            stepCreate: 'create',
            stepVerify: 'verify',
            errorMsgWorkName: 'Please do not use number',
            errorMsgInvalidEmail: 'Please add a valid email',
            errorMsgWorkEmail: 'Please provide a work email',
            errorMsgEmailAlreadyExists: 'User with this email already exists.',
            forgotPassword: 'Forgot password?',
            errorMsgEmailPlusSign: 'You cannot use + in your email address',
            errorMsgPassword:
              'Password should be at least 8 characters and includes at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character',
            errorMsgConfirmPassword: "Passwords don't match",
            readyForKartera: 'Ready for Kartera?',
            launchDashboard: 'Launch Dashboard',
            readyPanelText1: `There is one more step, to verify your business and approve your account.`,
            readyPanelText2: `But let's wait with that for a moment and have a <strong>glimpse at the Kartera dashboard first</strong>. `,
            readyPanelText3: `<strong>The dashboard is for demo purposes only</strong> and not showing data related to any existing accounts.`,
          },
          invite: {
            title: 'Register',
            firstNameLabel: 'Legal First Name',
            lastNameLabel: 'Legal Last Name',
            password: 'Password',
            confirmPassword: 'Confirm Password',
            confirmButton: 'Confirm',
            successMessage: 'You are successfully registered. Please head to login',
            errorMessage:
              'The token for the invitation with email <{{email}}> was already used. Request is refused.',
            doneButton: 'Done',
            errorMsgPassword:
              'Password should be at least 8 characters and includes at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character',
            errorMsgConfirmPassword: "Passwords don't match",
          },
          onboarding: {
            title: 'Verify Your Business',
            back: 'Back',
            next: 'Next',
            submit: 'Submit Verification',
            discardTitle: 'Discard Business Verification?',
            discardMessage:
              'You will loose all information you have entered in the Business Verification form.',
            keepEditing: 'Keep Editing',
            discard: 'Discard',
          },
          leadership: {
            subtitle: 'Business Leadership',
            ownCompanyLabel: 'Do you own 25% or more (directly or indirectly) of the company?',
            ownCompanyMessage:
              'We can only process verifications submitted by company directors owning 25% or more.',
            isBrokerageLabel:
              'Is your company a registered brokerage firm, a securities exchange, an investment advisor, an investment company, or an investment fund of any kind?',
            isBrokerageMessage:
              'We can not process verifications submitted by companies of the above types.',
            publicCompanyLabel:
              'Is your company or any of its beneficial owners or controlling officers a 10% or more shareholder of a public company?',
            yes: 'Yes',
            no: 'No',
          },
          legalDetails: {
            subtitle: 'Legal Details',
            businessNameLabel: 'Business Name',
            businessNamePlaceholder: 'eg. Rize Financial Technologies Inc.',
            businessNameNote: 'As it appears in your incorporation documents (eg. IRS forms)',
            employerIdentificationLabel: 'Employer Identification Number (EIN)',
            checkEIN: "Don't have an EIN",
            einError: 'Enter a valid EIN',
            noEINMessage: `To comply with regulations and get your verification processed you must supply us with an EIN.`,
            noEINMessage2: `For more information, visit the `,
            website: 'IRS website',
            legalStructureLabel: 'Legal Structure',
            legalStructurePlaceholder: 'Select Legal Structure',
            incorporationDetails: 'Incorporation Details',
            dateLabel: 'Incorporation Date',
            datePlaceholder: 'Choose Date',
            dateError: 'Please add a valid date',
            countryLabel: 'Country',
            countryPlaceholder: 'United States of America',
            countryNote: 'We only provide our services to companies incorporated in the US.',
            stateLabel: 'State',
            statePlaceholder: 'Select State',
            optionCCORP: 'C-Corporation',
            optionSCORP: 'S-Corporation',
            optionLLC: 'Limited Liability Company (LLC)',
            optionPARTNERSHIP: 'Partnership',
            optionNONPROFIT: 'Non-Profit',
            optionSOLEPROP: 'Sole Proprietorship',
          },
          business: {
            subtitle: 'Business Details',
            businessTypeLabel: 'What type of business do you operate?',
            businessTypePlaceholder: 'Select Business Type',
            businessOtherTypeLabel: 'Name or describe your type of business',
            businessOtherTypePlaceholder: 'e.g. Furniture and Home Furnishing',
            businessTypeError: 'Select a valid business type',
            productDescriptionLabel: 'Describe your products and customers',
            productDescriptionPlaceholder: `e.g. We sell fashion jewelry online, and also have a few pop-up stores. We sell directly to consumers in the United States.`,
            productDescriptionNote: `Provide details on your services, customers, and countries served. Applications with detailed responses are more likely to be approved.`,
            contactDetails: 'Contact Details',
            countryCodeLabel: 'Country Code',
            countryCodePlaceholder: 'Select',
            phoneNumberLabel: 'Phone Number',
            phoneNumberPlaceholder: '111-111-1111',
            phoneNumberError: 'Enter a valid phone number',
            companyWebsiteLabel: 'Company Website',
            companyWebsitePlaceholder: 'www.yourcompany.com (optional)',
            companyWebsiteError: 'Company website must contain a domain',
            optionAdtech: 'Adtech',
            optionAdvancedManufacturing: 'Advanced manufacturing',
            optionAgtech: 'Agtech',
            optionAI_ML: 'Artificial intelligence and machine learning (AI/ML)',
            optionAudiotech: 'Audiotech',
            optionAR: 'Augmented reality (AR)',
            optionAutonomousCars: 'Autonomous cars',
            optionBeauty: 'Beauty',
            optionBigData: 'Big Data',
            optionCannabis: 'Cannabis',
            optionCarsharing: 'Carsharing',
            optionCleantech: 'Cleantech',
            optionClimateTech: 'Climate tech',
            optionCloudtechAndDevOps: 'Cloudtech and DevOps',
            optionConstructionTechnology: 'Construction technology',
            optionCryptocurrencyAndBlockchain: 'Cryptocurrency and blockchain',
            optionCybersecurity: 'Cybersecurity',
            optionDigitalHealth: 'Digital health',
            optionEcommerce: 'Ecommerce',
            optionEdtech: 'Edtech',
            optionEphemeralContent: 'Ephemeral content',
            optioneSports: 'eSports',
            optionFemtech: 'Femtech',
            optionFintech: 'Fintech',
            optionFoodtech: 'Foodtech',
            optionGaming: 'Gaming',
            optionHealthtech: 'Healthtech',
            optionHRtech: 'HRtech',
            optionImpactInvesting: 'Impact investing',
            optionIndustrials: 'Industrials',
            optionInfrastructure: 'Infrastructure',
            optionInsurtech: 'Insurtech',
            optionIoT: 'Internet of Things (IoT)',
            optionLegalTech: 'Legal tech',
            optionLifeSciences: 'Life sciences',
            optionLOHAS: 'Lifestyles of Health and Sustainability (LOHAS) and wellness',
            optionManufacturing: 'Manufacturing',
            optionMarketingTech: 'Marketing tech',
            optionMarketplaces: 'Marketplaces',
            optionMicroMobility: 'Micro-mobility',
            optionMobile: 'Mobile',
            optionMobileCommerce: 'Mobile commerce',
            optionMobilityTech: 'Mobility tech',
            optionMortgageTech: 'Mortgage tech',
            optionNanotechnology: 'Nanotechnology',
            optionOilAndGas: 'Oil and gas',
            optionOncology: 'Oncology',
            optionPetTech: 'Pet tech',
            optionRealEstateTech: 'Real estate tech',
            optionRestaurantTech: 'Restaurant tech',
            optionRidesharing: 'Ridesharing',
            optionRoboticsAndDrones: 'Robotics and drones',
            optionSaaS: 'Software as a service (SaaS)',
            optionSpaceTech: 'Space tech',
            optionSupplyChainTechnology: 'Supply chain technology',
            optionTMT: 'Technology, media and telecommunications (TMT)',
            optionVR: 'Virtual reality (VR)',
            optionWearablesAndQuantifiedSelf: 'Wearables and quantified self',
            option3DPrinting: '3D printing',
            optionOther: 'Other',
          },
          businessAddress: {
            subtitle: 'Business Address',
            countryLabel: 'Country',
            countryPlaceholder: 'Select Country',
            countryNote: 'We only provide our services to companies incorporated in the US.',
            addressLabel: 'Address',
            addressPlaceholder: 'Address',
            addressNote:
              'No P.O. boxes or virtual addresses. We will not send mail to this address.',
            optionalAddressPlaceholder: 'Address (optional)',
            optionalAddressNote: 'Apartment number, unit, floor, etc',
            cityPlaceholder: 'City',
            statePlaceholder: 'Select State',
            zipcodePlaceholder: 'Zip Code',
            zipcodeError: 'Enter a valid zip code',
            postalcodeError: 'Enter a valid postal code',
          },
          personalDetails: {
            subtitle: 'Your Personal Details',
            relationshipToCompanyLabel: 'Relationship to Company',
            relationshipToCompanyPlaceholder: 'Select Relationship',
            relationshipToCompanyNote:
              'We can only process verifications submitted by company directors owning 25% or more.',
            personalInformation: 'Personal Information',
            nameLabel: 'Name',
            namePlaceholder: 'John',
            lastnameLabel: 'Lastname',
            lastnamePlaceholder: 'Smith',
            dateOfBirthLabel: 'Date of Birth',
            dateOfBirthPlaceholder: 'Choose Date',
            dateError: 'Enter a valid date',
            dateOfBirthError: 'Enter a valid date of birth',
            dateOfBirthMinimunError: 'Minimum age is 16 years',
            genderLabel: 'Gender',
            genderPlaceholder: 'Select Gender',
            optionMale: 'Male',
            optionFemale: 'Female',
            optionChiefExecutive: 'Chief Executive Officer',
            optionManaging: 'Managing Director',
            optionPresident: 'President',
            optionVice: 'Vice President',
            optionDirector: 'Director',
            optionChairperson: 'Chairperson',
            optionGeneral: 'General Manager',
            optionTechnical: 'Technical Director',
            optionCreative: 'Creative Director',
            optionChiefFinancial: 'Chief Financial Officer',
            optionChiefTechnology: 'Chief Technology Officer',
            optionChiefInformation: 'Chief Information Officer',
            optionChiefOperation: 'Chief Operating Officer',
            optionSole: 'Sole Proprietor',
            optionAdministrator: 'Administrator',
            optionPrincipal: 'Principal',
            optionManagingPartner: 'Managing Partner',
          },
          citizenshipDetails: {
            subtitle: 'Your Citizenship Details',
            passportInformation: 'Passport Information',
            citizenshipLabel: 'Country of Citizenship',
            citizenshipPlaceholder: 'Select Country',
            SSNLabel: 'Social Security Number (SSN)',
            SSNNote:
              'We use the SSN for identity verification only. Personal credit scores will not be affected.',
            SSNError: 'Invalid social security number',
            SINLabel: 'Social Insurance Number (SIN)',
            SINNote:
              'We use the SIN for identity verification only. Personal credit scores will not be affected.',
            SINError: 'Invalid social insurance number',
            passportCode: 'Passport Code',
            passportError: 'Invalid Passport Error',
            passportNumber: 'Passport Number',
            selectCountry: 'Select Country',
          },
          contactDetails: {
            subtitle: 'Contact Details',
            countryLabel: 'Country of Residence',
            countryPlaceholder: 'Select Country',
            addressLabel: 'Address',
            addressPlaceholder: 'Address',
            addressNote:
              'No P.O. boxes or virtual addresses. We will not send mail to this address.',
            optionalAddressPlaceholder: 'Address (optional)',
            optionalAddressNote: 'Apartment number, unit, floor, etc.',
            cityPlaceholder: 'City',
            provincePlaceholder: 'Select Province',
            statePlaceholder: 'Select State',
            zipCodePlaceholder: 'ZIP Code',
            postalCodePlaceholder: 'Postal Code',
            zipCodeError: 'Enter a valid zip code',
            select: 'Select',
            postalCodeError: 'Enter a valid postal code',
            phoneMobile: 'Phone / Mobile',
            countryCodeLabel: 'Country Code',
            countryCodePlaceholder: 'Select',
            phoneNumberLabel: 'Phone Number',
            phoneNumberPlaceholder: '111-111-1111',
            phoneNumberError: 'Enter a valid phone number',
          },
          termsConditions: {
            subtitle: 'Terms & Conditions',
            beforeSubmitMessage:
              'Before submitting your application, please confirm the statements below on behalf of your company:',
            authorizedMessage: `Last Updated: 23. May 2023

Welcome to kartera.com! Rize Financial Technologies, Inc. (“Lynk,” “we,” or “us”) makes this website, and its content, features, technologies, and functions (collectively, the “Site”), including a non-production version of Lynk's Application Programing Interface(s) and Software Development Kit (collectively, “APIs”), available to you subject to these Terms of Use (“Terms”). These Terms are a legally binding agreement between you and Lynk regarding your use of and access to the Site and APIs.
            
PLEASE READ THE FOLLOWING TERMS CAREFULLY.
            
BY CLICKING “I ACCEPT” OR BY OTHERWISE ACCESSING OR USING THE SITE OR APIs, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR ACCESS OR USE OF THE SITE AND APIs, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THESE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO ACCESS OR USE THE SITE OR APIs. YOUR ACCESS TO AND USE OF THE SITE AND APIs, AND LYNK’S PROVISION OF THE SITE AND APIs TO YOU, CONSTITUTES AN AGREEMENT BY LYNK AND YOU TO BE BOUND BY THESE TERMS.

[Plain English: These are the terms that apply to your use of our website and your use of our APIs in a sandbox environment. Please read these terms. If you don’t agree with them, you shouldn’t access or use our website or our APIs.]
            
Lynk reserves the right to discontinue or amend the Site or APIs, or any portion or feature thereof, in Lynk’s sole discretion without notice. Lynk will not be liable for any changes to the Site or APIs or if, for any reason, all or any part of the Site is unavailable at any time or for any period. Without limiting the foregoing, you acknowledge that Lynk may change, deprecate, or republish its APIs from time to time, and it is your responsibility to ensure that calls or requests you make to the APIs are compatible with Lynk’s then-current APIs. From time to time, Lynk may restrict access to some parts of the Site, or the entire Site, to users, including registered users.
            
[Plain English: We can change this website or the APIs at any time. If we change the APIs, please make sure that your API calls are compatible with our updated APIs. We can also restrict access to any part of this website at any time.]
            
1.   Overview
            
A non-production version of Lynk’s enterprise fintech service for brands and banks is made available to you through this Site. Lynk’s fintech service enables brands to offer personalized banking products made available by banks that have partnered with Lynk. For our fintech service to be made available to you for commercial use, you must enter into a separate written agreement with Lynk. These Terms do not govern your commercial use of our fintech service through the Site.
            
[Plain English: If you would like access to our fintech service for brands and banks for commercial use, you have to sign a separate agreement with us. These terms don’t apply to your commercial use of our fintech service.]
            
2.   Eligibility to Use the Site and/or APIs
            
You must be at least 18-years old to use the Site or APIs. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 18-years old; (b) you have not previously been suspended or removed from the Site; and (c) your registration and your use of the Site and APIs is in compliance with any and all applicable laws and regulations. If you are an entity, organization, or company, the individual accepting these Terms on your behalf represents and warrants that they have authority to bind you to these Terms and you agree to be bound by these Terms.
            
[Plain English: If you agree to these terms, you’re at least 18 years old, haven’t been kicked off our website before, and are in compliance with laws when you use our website and APIs. If you’re agreeing to these terms on behalf of your company, you promise you have your company’s authority to do so.]
            
3.   Your Conduct
            
You may use the Site and APIs only for lawful purposes and in accordance with these Terms. When using this Site or APIs, you understand and agree that:

You will only use this Site and APIs as intended, and will not use this Site or APIs in any way that violates any applicable federal, state, local or international law or regulation;
You will not violate, or encourage others to violate, any right of a third party, including by infringing or misappropriating any third-party intellectual property right;
You will not disrupt, interfere, infect, circumvent, or alter (or attempt to do any of the foregoing) the Site or APIs (or any security-related feature or access control mechanism of the Site or APIs) in any way, including by: (a) disabling or circumventing features that prevent or limit use or copying of any content; or (b) reverse engineering, decompiling, or otherwise attempting to discover the source code of any portion of the Site or APIs, except to the extent that the activity is expressly permitted by applicable law or agreed to by you and Lynk;
You may only use the Site and any content available on the Site as provided to you through the Site, unless otherwise expressly agreed by Lynk. You will not reproduce, distribute, publicly display, publicly perform, copy, modify, create derivative works from, or alter the Site (including any content or intellectual property contained in or provided through it) or APIs;
You will not interfere with the operation of the Site or APIs or any authorized user’s use of the Site or APIs, including by uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code or by interfering with or disrupting any network, equipment, or server connected to or used to provide the Site or APIs;
You will not perform any fraudulent activity, including impersonating any person or entity, claiming a false affiliation, accessing any other Site account without permission, or falsifying your age or date of birth;
You will not sell or otherwise transfer the access granted under these Terms or any right or ability to view, access, or use any content or services on the Site or APIs;
You may not obstruct, remove, or otherwise alter any proprietary rights, compliance, or other notices that may be provided with the content or services on the Site or with APIs;
You will ensure all persons who access the Site or APIs through your internet connection are aware of these Terms and comply with them; and
Lynk may, at any time and for any reason, terminate or block your access to this Site or APIs.
[Plain English: Please use this website and APIs for legal purposes and in a way that doesn’t violate any laws or someone else’s rights. You should not interfere with the operation or the security of this website or the APIs. We ask that you please respect our intellectual property rights in this website and the APIs. For example, do not copy, transfer, or sell any part of this website or the APIs or anything else we provide through this website. You’re responsible for any of your users’ activities in connection with this website and the APIs.]

You will use and access (or attempt to access) the APIs only in accordance with their technical documentation and Lynk’s then current developer policies (both as may be made available to you through Lynk’s developer portal), and these Terms. You understand that Lynk sets and enforces limits on your use of the APIs (e.g., limiting the number of API requests that you may make), as further described in the documentation and developer policies. You agree to, and will not attempt to circumvent, such limitations. You agree that we may monitor your use of the APIs to enforce these limitations, ensure quality, improve our services, and otherwise ensure compliance with these Terms. If you would like to use the APIs beyond our published limitations, you must obtain our express consent, and we may decline such request or condition acceptance on your agreement to additional terms. Lynk is not responsible for disruption to any of your existing integration(s) that occur as a result of you exceeding our API limitations.      
[Plain English: Please use our APIs in accordance with their technical documentation and our policies.  We set limits on your use of our API and monitor your use, so we ask that you not exceed those limits. Please reach out to Lynk if you need to use our APIs beyond the set limits.]

4.   Accounts and Registration

You may create an online user profile consisting of a username, password, and other information that you provide. It is a condition of your use of the Site that all the information you provide on the Site is correct, current, and complete.

You understand and agree that you are solely responsible for maintaining the privacy and security of your usernames and passwords, and for any activity associated with your online user profile. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information. In the event that your username and password are compromised or you are aware of any unauthorized activity being conducted with your user credentials, you agree to immediately notify Bond at terms@bond.tech.

[Plain English: Please provide accurate information when you create your profile on this website. Make sure you keep your user name and password safe and email us at terms@bond.tech if you think your username and password are being used suspiciously.]

5.   Intellectual Property

Subject to your complete and ongoing compliance with these Terms, Bond grants you, solely for your personal, non-commercial use, a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the Site and APIs.

You understand and agree that, as between you and Bond, Bond holds all right, title, and interest to the Site, the Materials (as defined below), APIs, and all intellectual property, material, content, features, and services provided through the Site, as well as the design, selection, and arrangement thereof. The foregoing applies regardless of (i) the form of intellectual property; (ii) how it was provided to you; or (iii) your geolocation. Except as expressly authorized by Bond, you may not make use of the Site, any Materials (as defined below), or APIs. Bond reserves all rights to the Site, Materials, and APIs not granted expressly in these Terms.

If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Site or APIs in breach of these Terms, your right to use the Site (or APIs) will cease immediately and you must, at Bond’s option, return or destroy any copies of the materials you have made. Any use of the Site or APIs not expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other laws.

[Plain English: You may access and use this website and the APIs as long as you comply with these terms. You understand that Bond owns this website (and any content on this website), Bond’s brand, and the Bond APIs. If you don’t comply with these terms, your access to this website and the APIs will immediately end.]

Bond maintains trademarks, service marks, logos, domain names, and other branding material for its various organizations, products, and services (collectively, “Materials”), and has a strong interest in maintaining the integrity of its intellectual property rights in those Materials. You understand and agree that you will not use any Materials, whether or not intellectual property rights in those Materials have been registered or applied for, without the prior written consent of Bond.

If you choose to provide input and suggestions regarding problems with or proposed modifications or improvements to the Site or APIs (“Feedback”), then you hereby grant Bond an unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to exploit the Feedback in any manner and for any purpose, including to improve the Site, APIs, and create other products and services.

[Plain English: We own any feedback you give us about this website or the APIs and we can freely use that feedback.]

6.   Privacy and Confidentiality

Protecting the privacy and security of your information is important to Bond. Please review Bond’s Privacy Policy to better understand how we collect, protect, and use your information, as well as how we may collect and use information about your general use of the Site and APIs, which we may collect to, among other things, improve your experience and better provide products and services. Bond’s Privacy Policy is incorporated by reference into, and is a part of, these Terms.

[Plain English: Please review our Privacy Policy to better understand how we use and protect your information. Our Privacy Policy is part of these terms.]

In your use of the Site or APIs or in other interactions with us, you may be given access to certain non-public information, software, specifications, or code (collectively, “Confidential Information”), which is confidential and proprietary to Bond. Without limiting the foregoing, the Bond fintech service and APIs are Bond’s Confidential Information. You may use Confidential Information only as necessary in exercising such rights as are granted to you in these Terms. Unless otherwise expressly agreed in writing by Bond, you agree not to disclose, publish, or disseminate any Bond Confidential Information to any third party or use any such Confidential Information for your own or any third party’s benefit. You may not use or export any Bond Confidential Information in violation of United States law or the laws of the jurisdiction in which you obtained the information. You further agree to take reasonable precautions to prevent any unauthorized use, disclosure, publication, or dissemination of Confidential Information. You acknowledge that your unauthorized disclosure or use of Bond’s Confidential Information could cause irreparable harm and significant injury to Bond that may be difficult to ascertain. Accordingly, you agree that Bond will have the right to seek immediate injunctive relief, without the necessity of posting a bond or other security, to enforce your obligations under these Terms in addition to any other rights and remedies it may have. If you are required by law, regulation, or a valid binding order of a court of competent jurisdiction to disclose any Confidential Information, you may make such disclosure but only if you have notified us before making such disclosure and have used commercially reasonable efforts to limit the disclosure and to seek confidential treatment of such information.

[Plain English: You may receive Bond confidential information when you use this website or the APIs or in other interactions with us. Bond’s confidential information includes our fintech service and our APIs.]

7.   Third-Party Terms

This Site may provide tools that enable you to export information to third-party services and may contain links to third-party sites, including some services and sites operated by our business partners or clients. By using any of these tools, you agree that Bond may transfer your information to the applicable third-party service. You understand and agree that: (a) Bond has no control over these third-party services and sites; (b) to the fullest extent permitted by law, Bond is not responsible for any third-party service’s use of your exported information and is not responsible for the content of any third-party site; and (c) Bond is not liable for any damages, losses, or expenses that you may incur while using any of these third-party services and sites.

If you decide to access any of these third-party services or sites, you do so entirely at your own risk and subject to the terms and conditions of use for such services or sites. You further understand and agree that any links, references, logos, or news relating to third parties on the Site does not constitute an endorsement of, an advertisement for, or partnership with any such third party.

The Site (or APIs) may also include or incorporate third-party software components that are generally available free of charge under licenses granting recipients broad rights to copy, modify, and distribute those components (“Third-Party Components”). Although the Site (and APIs) are provided to you subject to these Terms, nothing in these Terms prevents, restricts, or is intended to prevent or restrict you from obtaining Third-Party Components under the applicable third-party licenses or to limit your use of Third-Party Components under those third-party licenses.

[Plain English: By using any tools on our website that allow you to send information to third party services, you’re giving us permission to transfer your information to those services. Since we don’t own those services or the third party websites they’re related to, we aren’t responsible for the services or the websites, and you’re using those services and websites at your own risk. Also, if this website or the APIs include any third party components that are subject to separate license terms, those separate license terms continue to  govern your use of any of those components.]

8.   Modifications to Terms and Additional Terms and Conditions

Bond may, from time to time, change these Terms with or without notice to you. It is your responsibility to review the most current version of these Terms prior to your use of the Site or APIs. All changes are effective immediately when posted and your continued use of the Site or APIs following the posting of revised Terms means that you accept and agree to the changes. Disputes arising under these Terms will be resolved in accordance with the version of these Terms that was in effect at the time the dispute arose.

[Plain English: We may change these terms from time to time. Please review these terms before you use this website or the APIs to make sure you’re up to date on the most recent version.  If you keep using this website and/or the APIs after these terms have changed and gone into effect, that means you have accepted those changes and they’re binding on you. If you don’t agree with the changed terms, you must stop using this website and the APIs.]

Bond may in the future offer you access to new content, websites, webpages on this Site, applications, and other products and services (collectively, “New Offerings”), which may be subject to additional terms and conditions, including additional third-party terms. Any such additional terms and conditions will be provided or made available to you prior to or when accessing New Offerings through a hyperlink, a click-through agreement, or other similar means. As with the Site and APIs, your use of any New Offerings will constitute your acceptance of any such additional terms and conditions.

[Plain English: If we give you access to new content, offerings, or webpages on this website, those new offerings may have additional terms that apply to them. We’ll make those terms available to you before you access these new offerings. If you use the new offerings, that means you have accepted those additional terms and they’re binding on you.]

9.   User-Provided Content

While using the Site or APIs, you may be asked to and may provide commentary, information, or other content through the Site (collectively, “User-Provided Content”) to Bond and other users. Any User-Provided Content you provide will be considered non-confidential and non-proprietary. By providing User-Provided Content to or via the Site, you grant Bond a worldwide, royalty-free, perpetual, irrevocable, non-exclusive, fully paid license (with the right to sublicense) to use, reproduce, modify, display, and distribute any User-Provided Content and you acknowledge and warrant that you have the right and authority to provide such license to Bond. You understand and acknowledge that you are responsible for any User-Provided Content you submit or contribute, and you, not Bond, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness. Bond is not responsible, or liable to any third party, for the content or accuracy of any User-Provided Content posted by you or any other user of the Site.

[Plain English: We’ll assume that any commentary or information you give us through this website is not confidential to you and that you have the right to provide it to us. By providing this commentary or other information, you give us a right to use it in any way, but you’re still responsible for it.]

10. Term and Termination

These Terms will continue until either party chooses to terminate them. If you violate any provision of these Terms, these Terms and your authorization to access the Site or APIs automatically terminates. In addition, Bond may, in its sole discretion and without liability to you, terminate these Terms or your account on the Site, or suspend or terminate your access to the Site or APIs, at any time for any reason or no reason, with or without notice. Without limiting the foregoing, Bond may, in its reasonable determination, deactivate your user name(s) and password(s) and/or temporarily suspend access to the APIs or a portion thereof, if and to the extent Bond can substantiate that continued use of the APIs may result in harm to the APIs (including the security of the systems used to provide the APIs) or other Bond customers or the rights of third parties. You may terminate your account and these Terms at any time by contacting Bond at terms@bond.tech. Upon termination of these Terms: (a) your license rights will terminate and you must immediately cease all use of the Site and APIs; and (b) you will no longer be authorized to access your account or the Site or APIs. Sections 5 and 9–15 will survive any termination hereof.

[Plain English: You can close your account at any time by emailing us at terms@bond.tech. If you violate these terms we may cut off your access to this website and the APIs. We may also suspend or close your account or end your access to this website or the APIs at any time, including if we think that your use of the APIs may harm the APIs. If these terms are terminated, your account will be closed and your right to use this website and the APIs also ends.]

11.  Disclaimer of Warranties

You understand that Bond cannot and does not guarantee or warrant that files available for downloading from the internet or this Site will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to the Site for any reconstruction of any lost data.

[Plain English: You’re responsible for protecting your information from viruses and backing up your information.]

BOND WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE, APIs, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR APIs OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON THE SITE, OR ON ANY SITE LINKED TO IT.

BOND DOES NOT MAKE, AND EXPRESSLY DISCLAIMS, ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND IN CONNECTION WITH THESE TERMS, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, TO THE FULLEST EXTENT PERMITTED BY LAW. THE INFORMATION PRESENTED ON OR THROUGH THE SITE IS MADE AVAILABLE SOLELY FOR GENERAL INFORMATIONAL PURPOSES. USE OF THIS SITE AND APIs IS AT YOUR SOLE RISK. THIS SITE AND THE APIs ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND, INCLUDING,BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. FURTHER, BOND DOES NOT WARRANT AND MAKES NO REPRESENTATIONS THAT THIS SITE (OR ANY PORTION THEREOF) OR THE APIs WILL MEET YOUR NEEDS OR EXPECTATIONS; WILL BE UNINTERRUPTED, SECURE, OR FREE FROM ERROR; OR THAT INFORMATION ON THE SITE IS CURRENT, ACCURATE, OR SHOULD BE RELIED UPON. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

[Plain English: We provide this website, anything on this website, and the APIs “as is”.]

12. Limitations of Liability

TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL: (A) BOND, ITS AFFILIATES OR THEIR RESPECTIVE SHAREHOLDERS, DIRECTORS, MEMBERS, PARTNERS, OFFICERS, EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS, SERVICE PROVIDERS, SUCCESSORS, ASSIGNS, AND OTHER PERSONNEL (COLLECTIVELY, “BOND PARTIES”), BE LIABLE TO YOU FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, OR INCIDENTAL DAMAGES INCURRED BY YOU, UNDER ANY THEORY OF LIABILITY AND HOWEVER CAUSED, EVEN IF FORESEEABLE, INCLUDING, BUT NOT LIMITED TO, LOST PROFITS, LOSS OF GOODWILL OR REPUTATIONAL HARM, DATA LOSS, OR OTHER INTANGIBLE LOSSES, ARISING IN ANY WAY OUT OF OR IN CONNECTION WITH THESE TERMS, THE SITE, AND/OR THE APIS; AND (B) THE AGGREGATE LIABILITY OF THE BOND PARTIES TO YOU FOR ALL CLAIMS IN ANY WAY ARISING OUT OF OR RELATING TO THESE TERMS, THE SITE, AND/OR THE APIS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO $100. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

[Plain English: You're using this website and APIs for free! Any damages that Bond might owe you in connection with these terms, this website, or the APIs cannot be more than $100.]

13. Indemnification

To the fullest extent permitted by law, you are responsible for your use of the Site and APIs, and you will defend, indemnify and hold harmless the Bond Parties from and against any claim brought by a third party, and any related liability, damage, loss, and expense, including reasonable attorneys’ fees and costs, arising out of or connected with: (a) your unauthorized use of, or misuse of, the Site or APIs; (b) your violation of these Terms or any applicable law or regulation; (c) your violation of any third-party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (d) any dispute or issue between you and any third party.

[Plain English: If someone comes after us for your misuse of this website or the APIs, for your violation of these terms, for your violation of laws, for your violation of their rights, or due to a dispute you have with someone else, then you have to defend us against that claim and cover the fines, damages, or costs arising out of any of those claims.]

14. Dispute Resolution

If you have any dispute with Bond regarding these Terms, you must: (a) notify Bond of the dispute by emailing a description of the dispute to terms@bond.tech (the “Dispute Notice”); and (b) cooperate with Bond or its authorized representative to resolve the dispute. Within 15 days of Bond receiving your Dispute Notice, you and Bond each agree to attempt to resolve the dispute by negotiation.

If the dispute is not satisfactorily resolved by negotiation between you and Bond after the 15-day period (or, where both you and Bond agree to a longer period, that longer period), then the parties may pursue any remedies available to them at law or in equity.

Nothing in this Section denies Bond the right to seek injunctive relief in a court of law in relation to any dispute arising under these Terms.

[Plain English: If you have a dispute with us about these terms, please shoot us an email at terms@bond.tech with a description of your dispute and we’ll try to solve it together. If we can’t resolve the dispute in 15 days, then each of us is free to decide what it will do next.]

15. Miscellaneous

Governing Law and Venue. These Terms and your use of this Site and APIs are subject by the laws of the State of California, without regard to its conflict of laws provisions. You and Bond submit to the personal and exclusive jurisdiction of the state courts and federal courts located within San Francisco County, California for resolution of any lawsuit or court proceeding permitted under these Terms. Although you may access this Site or APIs in other states or countries, your use of this Site and APIs still subjects you to the exclusive jurisdiction of these courts.

[Plain English: If we end up having a legal dispute, California laws will apply.]

International Use. The Site and APIs are intended for visitors located within the United States. We make no representation that the Site or APIs are appropriate or available for use outside of the United States. Access to the Site or APIs from countries or territories  by individuals where such access is illegal is prohibited. These Terms may be provided to you in a language other than English for your convenience, but only the English-language version of the Terms will govern your use of the Site and/or APIs.

[Plain English: This website and the APIs are meant for US visitors only. Don’t access this website or the APIs if your country doesn’t allow it. Only the English language version of these terms apply.]

Assignment. You may not assign or transfer these Terms or your rights under these Terms, in whole or in part, by operation of law or otherwise, without our prior written consent. We may assign these Terms at any time without notice or consent.

[Plain English: You need to ask for our permission before transferring these terms or your rights under these terms. We can freely transfer these terms.]

Notices and Communications. Bond may occasionally provide you notices about changes to this Site or APIs. Such notices may be provided via email or through the Site. We may also send you emails concerning our products and services, as well as those of third parties. You may opt out of promotional emails by following the unsubscribe instructions in the promotional email itself, and as further described in our Privacy Policy.

[Plain English: We can email you or send you notices through this website about changes to this website or the APIs. If we email you about our products and services and you don’t want to receive these emails, you can unsubscribe by following the instructions in our Privacy Policy.]

Consent to Electronic Communications. By using the Site and APIs, you consent to receiving certain electronic communications from us. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that those communications be in writing.

[Plain English: By using this website and the APIs, you’re telling us that you’re ok receiving emails from us and that those emails are the same as getting something in writing from us.]

Contact Information. The Site and APIs are offered by Bond Financial Technologies, Inc., headquartered at 345 California Street, Suite 600, San Francisco, California 94104. You may contact us by emailing us at terms@bond.tech.

No Waiver. You understand and agree no waiver by Bond of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Bond or any Bond Parties to enforce their rights under these Terms or at law will not be considered a waiver of such rights.

[Plain English: If Bond doesn’t enforce some part of these terms against you now, it doesn’t mean we can’t enforce them against you later.]

Severability. If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.

[Plain English: If one of the clauses in these terms is not enforceable, the rest of these terms are still valid and enforceable.]

No Support. We are under no obligation to provide support for the Site or APIs. In instances where we offer support, the support will be subject to published policies or a separate written agreement.

[Plain English: We’re not on the hook to provide support for this website or the APIs.]

Entire Agreement. These Terms constitute the entire agreement between you and Bond regarding your access and use of the Site and APIs and supersede any previous terms, conditions, or agreements of the parties regarding your use of the Site and APIs.

[Plain English: This is the only set of terms that governs our relationship.]
            
            ‍`,
            submittingCheckbox:
              'By submitting this application, you accept and agree to the applicable terms and conditions, including the Lynk Platform Agreement, and Privacy Policy.',
            ownershipCheckbox:
              'I hereby certify, to the best of my knowledge, that the information provided for controlling officers and business ownership is complete and correct.',
            previousStep: 'Previous Step',
            submitApplication: 'Submit Application',
            scrollInfo: 'You need to scroll down to be able to submit your application.',
            readyToAccept:
              'You are ready to accept our Terms & Conditions and submit your verification.',
          },
          WaitForProcess: {
            title: 'Business Verification Submited',
            messageLine1: 'In the meantime, you can start building the next startup unicorn.',
            messageLine2:
              'We will notify you once your application has been processed and your business has been verified.',
            titleRejected: 'Your Submission Has Been Denied',
            messageRejectedLine1:
              'We have reviewed your information and your business has not been approved.',
            messageRejectedLine2:
              'Please check your emails for more information or reach out to us via',
            titleError: 'Business Verification Not Submitted',
            messageErrorLine1:
              'An error has occurred while submitting your Business Verification and we were not able to process it.',
            messageErrorLine2: 'Please reach out to us via email for more information or support:',
            message1: `Business Verification has been submitted successfully!`,
            message2: `We will notify you once it has been processed and verified. In the meantime, continue building the next start up unicorn!`,
            titleApproved: 'Your Business is Now Verified!',
            messageApproved1: `We have reviewed your information and your business has been successfully verified and approved.`,
            messageApproved2: `Let's get started and check out your Kartera dashboard. `,
            close: 'Close',
            launchDashboard: 'Launch Dashboard',
          },
          login: {
            loginHeader: 'Welcome to Kartera!',
            loginSHOPIFYHeader: 'Shopify meets Kartera!',
            loginWOOCOMMERCEHeader: 'WooCommerce meets Kartera!',
            loginDescription: 'Welcome! Please enter your login credentials below to use Kartera.',
            loginSHOPIFYDescription:
              'To connect your account and setup the Kartera plugin, login to Shopify:',
            loginWOOCOMMERCEDescription:
              'To connect your account and setup the Kartera plugin, login to WooCommerce:',
            emailPlaceholder: 'Email',
            passwordPlaceholder: 'Password',
            forgetButton: 'Forgot password?',
            loginButton: 'Login',
            registerDescription: 'Don’t have an account?',
            registerButton: 'Register now',
            verifyButton: 'Verify',
            otptitle: 'One Last Step ...',
            loginVia: 'or continue to login via',
            otpDescription: `We have sent you an OTP code to your email to verify your identity before you can access Kartera.`,
            totpDescription: `You should check your authenticator app get your 6-digit number in order to verify your identity before you can access Kartera.`,
            errorMsgInvalidEmail: 'Please add a valid email',
            errorMsgWorkEmail: 'Please provide a work email',
            errorMsgEmailPlusSign: 'You cannot use + in your email address',
            errorMsgPassword: 'Password field can not be empty',
            passwordUpdated: 'Password updated!',
            passwordUpdatedMessage:
              'Your password has been reset successfully. Please use your new password to login again.',
            errorMsgIncorrectEmailOrPassword: `Incorrect email or password.\n {{attempts}} more attempts remaining.`,
            errorMsgAccountBlocked: `Account blocked.\n Reset password to unlock`,
          },
          newProductModal: {
            title: 'How would you like to build your product?',
            templateTitle: 'Fully brandable web & mobile templates',
            templateDescription: `You can make any template do what you want. 
                          It only takes seconds to style and add features.`,
            templateComingSoon: 'Coming soon!',
            embeddedTitle: 'Take full control with embedded finance',
            embeddedDescription: `Own the entire customer journey by leveraging our robust 
                          and dynamic APIs & SDKs.`,
          },
          accountConnectModal: {
            connectButton: 'Connect Now',
            later: 'Later',
            learnMorePliad: 'learn more about Plaid',
            title: 'Connect your bank account with Kartera ',
            description1:
              '<strong>To unlock all features of Kartera and enable secure withdrawals, connect your corporate bank account today.</strong>',
            description2:
              "We've partnered with Plaid, a trusted third-party vendor, to handle the secure connection process of your bank account and our platform.",
            description3:
              '<strong>Rest assured, your data is safe and your privacy is our priority.</strong> Plaid maintains the highest security standards, trusted by numerous financial institutions and applications. When you connect, Plaid securely establishes a connection with your bank, ensuring the privacy of your login credentials and personal information.',
            description4:
              'You can also connect your bank account later via the settings. (Click on your the user icon in the top right --> Settings --> Bank Account ...)',
          },
          productsList: {
            portfolioHeader: 'MY PORTFOLIO',
            createButtonText: 'Create New Product',
            searchPlaceholder: 'Search',
            createCapital: 'CREATE NEW PRODUCT',
          },
          dataGrid: {
            exportButton: 'Export',
            filter: 'Filter',
            matchAll: 'Match all of the following rules',
            fields: 'Fields',
            cancel: 'Cancel',
            apply: 'Apply',
            equals: 'equals',
            between: 'between',
            selectField: 'Select Field',
            selectOperator: 'Select Operator',
            selectValue: 'Select Value',
            enterField: 'Enter Field',
          },
          userList: {
            title: 'Users',
            searchPlaceholder: 'Search Users',
            colId: 'Id',
            active: 'Active',
            noActive: 'Not Active',
            colType: 'Type',
            colCreatedAt: 'Created At',
            colSignup: 'Sign up date',
            colName: 'Name',
            colEmail: 'Email',
            colLastActive: 'Last Active',
            colStatus: 'Status',
            statusActive: 'Active',
            statusInProgress: 'In Progress',
            statusRefunded: 'Refunded',
            statusRejected: 'Rejected',
            noUserFound: 'No users yet',
            csvFileName: 'product_users',
          },
          userLinkTest: {
            title: 'Plaid Link Test',
            testButton: 'Test your Bank',
          },
          partnerStatus: {
            approved: 'Approved',
            pending: 'Pending',
            error: 'Error',
            rejected: 'Rejected',
            absent: 'Absent',
            expired: 'Expired',
          },
          productStatus: {
            ready: 'Ready',
            pending: 'Pending',
            error: 'Error',
            rejected: 'Rejected',
            absent: 'Absent',
            expired: 'Expired',
          },
          userProfile: {
            title: 'User Profile',
            formTitle: 'Profile Details',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            password: 'Password',
            role: 'Role',
            confirmPassword: 'Confirm Password',
            confirmButton: 'Confirm',
          },
          codeSnippet: {
            copy: 'Copy',
          },
          companyUserList: {
            title: 'Users',
            searchPlaceholder: 'Search Users',
            inviteNewMembers: 'Invite new members',
            colId: 'Id',
            colName: 'Name',
            colEmail: 'Email address',
            colRole: 'Role',
            statusInProgress: 'In Progress',
            statusRefunded: 'Refunded',
            statusRejected: 'Rejected',
            noUserFound: 'No users yet',
            modalTitle: 'Invite to Lynk team',
            modalEmailsLabel: 'Emails',
            modalSendButton: 'Send Invitations',
            textAreaPlaceholder: 'You can copy and paste a list of emails...',
          },
          settings: {
            title: 'Settings',
            menuAccount: 'Account',
            menuCompany: 'Company',
            menuBankAccount: 'Bank Account',
            menuApiKeys: 'API Keys',
            menuStaff: 'Staff',
            menuSecurity: 'Security',
            menuRewards: 'Rewards Program',
            menuWhitelist: 'IP Whitelist',
            firstName: 'First Name:',
            lastName: 'Last Name:',
            email: 'Email:',
            role: 'Role:',
            apiVersion: 'API Version:',
            twoFAMethodLabel: '2FA Method:',
            tooltipText1:
              'Two-factor authentication (2FA) is enabled and your One-Time-Password (OTP) is currently sent to your email address for every login.',
            tooltipText2:
              'To increase the security of your account, we recommend using an authentication app.',
            tooltipTextEnabled:
              'Two-factor authentication (2FA) is enabled and a 6-digit code needs to be generated with your authentication app.',
            buttonTwoFA: 'Use Authentication App',
            changeEmailMessage: 'To change your email address or role contact',
            completeOnboard: 'Please complete onboarding first then come back for setting',
            buttonUpload: 'Upload Picture',
            buttonClose: 'Close',
            buttonCancel: 'Cancel',
            buttonLearnMore: 'Learn more about 2FA',
            buttonDisable: 'Disable',
            buttonNext: 'Next',
            buttonChange: 'Change',
            buttonSave: 'Save',
            buttonEdit: 'Edit',
            buttonFinish: 'Finish',
            buttonDone: 'Done',
            buttonChangePass: 'Change Password',
            buttonChangePicture: 'Change',
            buttonDelete: 'Delete',
            searchPlaceholder: 'Search Members',
            buttonInvite: 'Invite',
            buttonExport: 'Export',
            buttonContact: 'Contact Support',
            membersTitle: 'Members',
            staffTitle: 'Staff',
            membersSubtitle: 'Manage who in your company has access to Kartera.',
            staffSubtitle: 'Manage who has access to staff admin portal.',
            pageTitle: 'Change Password',
            description: `In order to protect your account, make sure your password contains:`,
            twoFATitle: 'Setup Authentication App',
            authAppText: 'Authentication App',
            twoFADescription:
              'Use your third-party authentication app (such as Google Authenticator, Authy, or Duo) to\n scan the QR code below or enter the key manually into your authentication app:',
            qrCodeDescription:
              'After the QR code has been scanned (or you entered the key) your authentication app\n with generate a 6-digit code that is needed for the next step.',
            twoFACodeDescription:
              'To finish setup, enter the 6-digit code you see in the authentication app',
            twoFADoneDescription:
              'A 6-digit code from your app will be requested every time you login to Kartera.',
            disableTitle: 'Disable Authentication App',
            disableDescription:
              'When you disable this security feature, you won’t be able to generate 6-digit codes with your authentication app anymore. Instead, a One-Time-Password will be sent to your email address for every login.',
            disableCommand:
              'To disable your authentication app, you need to contact support and request the change.',
            codeIncorrect: 'Code is incorrect or expired. Try again.',
            password: 'Password',
            confirmPassword: 'Confirm Password',
            ButtonText: 'Set Password',
            resendButtonText: 'Resend email',
            backButtonText: 'Login',
            successText: 'Password has been successfully changed.',
            errorMsgPassword:
              'Password should be at least 8 characters and includes at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character',
            errorMsgNewPassword: 'The new password should be different from current password',
            errorMsgConfirmPassword: "Passwords don't match",
            character: 'Minimum 8 characters',
            upperCase: 'Minimum 1 uppercase letter',
            symbol: 'Minimum 1 symbol',
            number: 'Minimum 1 number ',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            repeatPassword: 'Repeat Password',
            colName: 'Name',
            colEmail: 'Email',
            colRole: 'Role',
            roleOwner: 'Owner',
            roleAdmin: 'Admin',
            roleReadOnly: 'Read Only',
            roleInvited: 'Invited',
            allRoles: 'All Roles',
            statusInProgress: 'In Progress',
            statusRefunded: 'Refunded',
            statusRejected: 'Rejected',
            noUserFound: 'No users yet',
            modalTitle: 'Invite to Lynk team',
            inviteTo: 'Invite to',
            inviteToStaff: 'Invite to Staff',
            inviteSent: 'Invite Sent',
            removed: 'removed',
            remove: 'Remove',
            modalEmailsLabel: 'Emails',
            modalSendButton: 'Send Invite',
            textAreaError: 'Enter at least one email address',
            invalidEmailsError:
              'The email addresses marked with red color are invalid or already been invited',
            textAreaPlaceholder: 'Type or copy & paste email addresses',
            removeMemberDialogText:
              'A removed member will no longer have access to Kartera or any of its services',
            invitesSentTo: 'Invites sent to {{counter}} people',
            inviteSentTo: 'Invite sent to a person',
            notConnectedDes1:
              '<strong>To unlock all features of Kartera and enable secure withdrawals, you need to connect your corporate bank account.</strong>',
            notConnectedDes2:
              "We've partnered with Plaid, a trusted third-party vendor, to handle the secure connection process of your bank account and our platform.",
            notConnectedDes3:
              '<strong>Rest assured, your data is safe and your privacy is our priority.</strong> Plaid maintains the highest security standards, trusted by numerous financial institutions and applications. When you connect, Plaid securely establishes a connection with your bank, ensuring the privacy of your login credentials and personal information.',
            connectedDes1:
              '<strong>Your bank account is handled by Plaid, our trusted third-party vendor.</strong>',
            connectedDes2:
              'Plaid acts as a secure intermediarmodaly, maintaining the highest security standards and is trusted by numerous financial institutions and applications.',
            connectedDes3:
              'We prioritize the safety and privacy of your financial information, and partnering with Plaid allows us to offer you a seamless and secure banking experience, ensuring the privacy of your login credentials and personal information.',
            connectButton: 'Connect Bank Account',
            disconnectButton: 'Disconnect',
            learnMorePlaid: 'Learn more about Plaid',
            learnMore: 'Learn more',
            status: 'Status',
            connectedAccount: 'Connected Account',
            connected: 'Connected',
            cancel: 'Cancel',
            disconnectHeader: 'Disconnect “Bank of America” account?',
            disconnectDescription1:
              'You will no longer be able to initiate withdrawals from our platform.',
            disconnectDescription2:
              'If you need to make withdrawals in the future, you will have to reconnect your bank account, which will come with a fee of 1.45 USD.',
            disconnectDescription3:
              'We urge you to consider these implications before proceeding with the disconnection.',
            imageErrorSIZETitle: 'File size too large',
            imageErrorSIZEText: 'Choose an image file smaller than 1 MB.',
            imageErrorTYPETitle: 'Invalid file format',
            imageErrorTYPEText: 'Only JPG and PNG files are accepted for profile pictures.',
            imageErrorUNKNOWNTitle: 'Upload failed',
            imageErrorUNKNOWNText:
              'Unknown error occurred during the upload process. Please try again.',
            rewardsTitle:
              'Take advantage of the opportunity to choose the type of rewards that align perfectly with your business and goals.',
            rewardsTextP1:
              'Whether you opt for "Instant Discount" to provide your users with immediate savings or allow them to accumulate rewards over time with "Cash Back Credit" or “Points System” - this critical decision can drive your growth and unlock incredible potential uplift.',
            rewardsTextP2: 'Start maximizing your rewards today!',
            setupRewards: 'Setup Rewards',
            active: 'Active',
            inactive: 'Inactive',
            rewardsType: 'Rewards Type',
            cashBackCredit: 'Cash Back Credit',
            change: 'Change',
            percentage: 'Percentage',
            edit: 'Edit',
            cashBackBreakage: 'Cash Back Breakage',
            days: 'Days',
            webhooks: 'Webhooks',
            addWebhook: 'Add Webhook',
            deleteRewardModalTitle: 'Remove “{{rewardType}}” Rewards?',
            deleteRewardModalDescriptionP1:
              'Any future transaction will be processed without a rewards program.',
            deleteRewardModalDescriptionP2:
              'This cannot be undone and a new rewards program needs to be set up if needed.',
          },
          platform: {
            disconnect: 'Disconnect',
            manage: 'Manage',
            account: 'Account',
            url: 'URL',
            connectionDate: 'Connection Date',
            connect: 'Connect',
            open: 'Open',
            cancel: 'Cancel',
            disconnectP1:
              'Disconnecting {{platform}} will permanently remove the connection to the eCommerce platform and disable the Kartera plugin.',
            disconnectP2:
              'If you need the connection again in the future you need to reconnect {{platform}}.',
            badgeTitle: 'Pay by Bank',
            badgeDiscountText: 'Save <strong>$1.36</strong> paying with your bank account.',
            badgeCashBackText:
              'Get <strong>$1.36 cash back credit</strong> paying with your bank account.',
            badgePointsText:
              'Get <strong>136 Kartera Points</strong> paying with your bank account.',
            badgePoweredByKartera: 'Powered by <strong>Kartera</strong>',
          },
          userDetail: {
            title: 'User Transactions',
            accountBalance: 'Account Balance',
            account: 'Account',
            noAccountsAvailable: 'No accounts available',
            chargebacks: '# of Changebacks',
            transactions: '# of Transactions',
            daysActive: '# of Days Active',
            colId: 'Payment ID',
            colAmount: 'Amount',
            colAccountId: 'Linked Account ID',
            colDate: 'Date',
            colDescription: 'Description',
            colType: 'Type',
            colStatus: 'Status',
            cancelled: 'Cancelled',
            initiated: 'Initiated',
            processed: 'Processed',
            completed: 'Completed',
            queued: 'Queued',
            currentBalance: 'Current Balance',
            availableBalance: 'Available Balance',
            searchPlaceholder: 'Search Transactions',
            backToUsersList: 'Back to Users List',
            noTransactionFound: 'No transactions yet',
            userListLink: 'Users',
            plaidLink: 'Plaid Link Test',
            userDetails: 'User Details',
            fullName: 'Full Name',
            emailAddress: 'Email',
            userStatus: 'User Status',
            registrationDate: 'Registration Date',
            csvFileName: 'user_transactions',
            statusActive: 'Active',
            statusLocked: 'Locked',
            statusSuspended: 'Suspended',
            statusTerminated: 'Terminated',
            statusUnactivated: 'Unactivated',
            merchantName: 'Merchant Name',
            merchantMid: 'Merchant ID',
            merchantMcc: 'Merchant Category',
            merchantCity: 'Merchant City',
            merchantState: 'Merchant State',
            merchantPostalCode: 'Merchant Postal code',
            merchantCountry: 'Merchant Country',
            currentBalanceHelp: `Total of all posted transactions. Your Current Balance does not include pending transactions or payments, which may take a few days to post.`,
            availableBalanceHelp: `Refers to your Credit Limit minus Current Balance and all pending transactions. Pending payments will increase your Available Balance.`,
          },
          card: {
            myCard: 'My Card:',
            virtualCard: 'Virtual Card',
            cardStatus: 'Card Status',
            cardNumber: 'CARD NUMBER',
            cardHolder: 'CARD HOLDER',
            cardCVV: 'CVV',
            expDate: 'EXP DATE',
            showDetails: 'Show Details',
          },
          cardAuthRequest: {
            pageTitle: 'Card Authorization Request',
            urlLabel: 'Webhook url',
            imageTitle: 'How does the card authorization request work?',
            saveButton: 'Save',
            cancelButton: 'Canel',
            editButton: 'Edit',
            deleteButton: 'Delete',
            urlErrorMessage: 'Please add a valid URL',
            authActiveDescription:
              'Provide a URL for your Webhook where all notifications for authorization requests will be sent to. Refer to the diagram below for more information.',
            authInactiveDescription:
              'Enable to receive notifications for authorization requests. Refer to the diagram below for more information.',
            status: 'Status',
            active: 'Active',
            inactive: 'Inactive',
            deleteDescription:
              'Deleting the Webhook URL will permanently remove it and you will no longer receive notifications for authorization requests.',
            deleteTitle: 'Do you want to show a title?',
          },
          productBuilderLayout: {
            featureStep: 'FEATURE',
            designStep: 'DESIGN',
            previewStep: 'PREVIEW',
            nextButton: 'Next Step',
            previousButton: 'Previous Step',
            headerText: 'EXPERIENCES PRODUCT BUILDER',
            descriptionText: 'To get started, use our product builder below:',
            portfolioButton: 'Back to Portfolio',
            nextStep: 'Next Step',
          },
          sidebar: {
            portfolioButton: 'Back to Portfolio',
            backButton: 'Back to Projects',
            createProductButton: 'Create Project',
            menuDashboard: 'Dashboard',
            menuProfile: 'Profile',
            menuUsers: 'Users',
            menuIntegrations: 'Integrations',
            menuCommercePlatforms: 'Commerce Platforms',
            menuPaymentWidget: 'Payment Widget',
            menu3rdParty: '3rd Party (coming soon)',
            menuReports: 'Reports & Analytics',
            menuTransactions: 'All Transactions',
            apiKeys: 'API Keys',
            webhooks: 'Webhooks',
            cardAuthorization: 'Card Auth Request',
            menuApiDocs: 'API Docs',
            menuFunds: 'Funds',
            offers: 'Offers',
            support: 'Support',
            transfers: 'Transfers',
            refunds: 'Refunds',
            accounts: 'Accounts',
            menuOffers: 'Offers',
            menuSettings: 'Settings',
            menuSupport: 'Support',
            digitalWallet: 'Digital Wallet',
            cardIssuing: 'Card Issuing',
          },
          table: {
            buttonAdd: 'Add',
            lastUpdated: 'Last updated: ',
            justNow: 'just now',
            aFewSecondsAgo: 'A few seconds ago',
            secondsAgo: 'seconds ago',
            minutesAgo: 'minutes ago',
            hoursAgo: 'hours ago',
            daysAgo: 'day ago',
          },
          productBuilderFeatures: {
            targetCustomer: 'Target Customer',
            productType: 'Type of Product',
            selectFeatures: 'Select Features',
          },
          productBuilderDesign: {
            mainTitle: 'Product Styling',
            mainDescription:
              'Upload your icons and set your styling for your web and mobile product.',
            productPreview: 'Product Preview',
            productPreviewDetails:
              'See a preview of how your product will look with your selection:',
            productTitle: 'Product Title & Tag Line',
            productDetails:
              'The Product Title is used wherever the title of the product appears, while the Tag Line is used on some templates where applicable.',
            productNamePlaceholder: 'Product name',
            tagLinePlaceholder: 'Tag line',
            productStylingTitle: 'Site & App Styling',
            primaryColorText: 'Primary Color',
            typeFaceColorText: 'Typeface Color',
            alertColorText: 'Alert Color',
            backForthColorText: 'Foreground/Background Color',
            ForeColorText: 'Foreground/Background Color',
            headerTypefaceText: 'Header Typeface',
            bodyTypefaceText: 'Body Typeface',
            colorPickerTitle: 'Palette',
            hexTitle: 'CUSTOM HEX',
            hexPlaceholder: '# 000000',
            logosIconsTitle: 'Logos & Icons',
            logoTitle: 'Logo Image',
            logoDetails:
              'Upload a logo image to replace your site title. You can use the Style Editor to adjust the size in many templates.',
            logoLink: 'Click here for image requirments',
            logoUpload: 'Upload Logo',
            iconTitle: 'Icon Image',
            iconDetails:
              'Using the field below, you can upload a browser URL & mobile app icon for use with your product. This icon can be in SVG and will be resized as needed',
            iconLink: 'Click here for image requirments',
            iconUpload: 'Upload Icon',
          },
          productBuilderPreview: {
            mainTitle: 'Preview Product',
            sectionDescription: 'Click on your logo below to preview your new product:',
            boxTitle: 'Rize Expense Management',
            tagsTitle: 'Tags:',
            approveButtonText: 'Approve',
            approvedButtonText: 'Approved',
            previewBoxTitle: 'Preview Rize Expense Management',
            portfolioButtonText: 'Go To Portfolio',
            congratsText: 'CONGRATULATIONS 🎉🎉',
            firstDescribe: 'Your product, ',
            secondDescribe:
              ', is ready for internal testing. Once you are ready to launch, publish your product and start delighting your users!',
          },
          notFound: {
            errorTextNotFound: 'Error 404',
            titleNotFound: 'Page Not Found',
            descriptionNotFound: `The page you're looking for might be not available or has moved.\nCheck the URL or navigate back to the previous page.`,
            support1NotFound: 'If the issue persists or you believe this is an error, please',
            support2NotFound: 'for assistance.',
            contactNotFound: 'contact support',

            errorTextUnderMain: '',
            titleUnderMain: 'Under Maintenance',
            descriptionUnderMain: `We're currently performing maintenance to enhance your experience.\nPlease check back soon.`,
            support1UnderMain: 'For urgent matters, reach out to our',
            support2UnderMain: 'team.',
            contactUnderMain: 'support',

            errorTextAccessDenied: 'Error 401',
            titleAccessDenied: 'Access Denied',
            descriptionAccessDenied: `You don't have permission to access this resource. `,
            support1AccessDenied: 'If believe this is an error, please',
            support2AccessDenied: 'for assistance.',
            contactAccessDenied: 'contact support',

            errorTextConnectionLost: '',
            titleConnectionLost: 'Connection Lost',
            descriptionConnectionLost: `Unable to establish a connection\nCheck your internet connection and try again. `,
            support1ConnectionLost: 'If the issue continues, please contact our',
            support2ConnectionLost: 'team',
            contactConnectionLost: 'support',

            errorTextUnknownError: '',
            titleUnknownError: 'Something Went Wrong ... ',
            descriptionUnknownError: `There was an issue accessing this page.`,
            support1UnknownError: 'If the issue persists, please',
            support2UnknownError: 'for assistance.',
            contactUnknownError: 'contact support',
          },
          apiBuilder: {
            productTitle: 'API title description',
            productDetails: 'The API title is used whenever product appears',
            apiNamePlaceholder: 'API title',
            descriptionPlaceholder: 'API description(optional)',
            previewBoxTitle: 'Preview Rize Expense Management',
            portfolioButtonText: 'Go To Portfolio',
            congratsText: 'CONGRATULATIONS 🎉🎉',
            firstDescribe: 'Your product, ',
            secondDescribe:
              ', is ready for internal testing. Once you are ready to launch, publish your product and start delighting your users!',
            nextButton: 'Finish',
            headerText: 'API PRODUCT BUILDER',
            descriptionText: 'To get started, use our API builder below:',
            portfolioButton: 'Back to Portfolio',
          },
          projectButton: {
            createProject: 'Create project',
            digitalWallet: 'Digital Wallet',
            cardIssuing: 'Card Issuing',
          },
          apiDocs: {
            user: 'User',
            productButton: 'Back to Product',
            parameter: 'Parameter',
            type: 'Type',
            description: 'Description',
            required: 'Required',
            definition: 'Definition',
            default: 'Default Value',
            queryParams: 'Query Params',
            exampleRequest: 'Example Request',
            exampleResponse: 'Example Response',
            apiDocsTitle: 'API Documentation',
            digitalWallet: 'Digital Wallet',
            cardIssuing: 'Card Issuing',
          },
          apiKeys: {
            title: 'API Keys',
            colApiKey: 'API Key',
            noApiKey: 'No API Keys yet',
            addApiKeyButton: 'Add API Key',
            APIDesc:
              "Manage and maintain your API keys right here to grant access to features and functionality of our platform.\nAn API key is a secure token that provides authorized access to our platform's functionalities and allows seamless integration with external systems or applications.",
            APIListDesc:
              'Manage and maintain your API keys right here to grant access to features and functionality of our platform (you can have up to 3 API keys).',
            newAPIKey: 'New API Key',
            modalText:
              '<strong>This is the only time the full API key is visible.</strong> You cannot recover them later.\n\nHowever, you can create new API keys at any time if needed.',
            modalButton: 'Close',
            colCreatedOn: 'Created on',
            copied: 'copied',
            deleteModalTile: 'Delete API Key',
            deleteModalConfirmButton: 'Delete',
            deleteModalConfirmationWord: 'delete',
            deleteModalP1:
              'Any applications or systems using this API Key will loose access once it is deleted.',
            deleteModalP2:
              'This action can not be undone and to regain access a new API Key needs to be generated.',
            deleteModalP3: '<strong>To confirm, enter the word "delete":</strong>',
            deleteModalPlaceholder: 'Type "delete"',
            searchPlaceholder: 'Search api keys',
            csvFileName: 'api_keys',
          },
          webhooks: {
            title: 'Webhooks',
            colId: 'Webhook Id',
            colUrl: 'URL',
            colEvent: 'Event',
            colCreatedOn: 'Created On',
            createWebhook: 'Create Webhook',
            editWebhook: 'Edit Webhook',
            modalButton: 'Create',
            searchPlaceholder: 'Search webhooks',
            csvFileName: 'webhooks',
            urlErrorMessage: 'Please add a valid URL',
            addWebhook: 'Add Webhook',
            messageP1: `Manage and maintain your webhooks from here, enabling seamless communication with our platform.`,
            messageP2: `Webhooks serve as data-triggered notifications, securely connecting your systems or applications with our platform's functionalities. Stay in control of your data flow and streamline integrations with external services.`,
            newWebhook: 'New Webhook',
            cancel: 'Cancel',
            add: 'Add',
            save: 'Save',
            urlPlaceholder: 'Enter URL for Webhook',
            eventPlaceholder: 'payment.update',
            deleteModalTitle: 'Delete Webhook for “payment.update”?',
            deleteModalP1:
              'Any applications or systems using this Webhook will loose access once it is deleted.',
            deleteModalP2:
              'This action can not be undone and to regain access a new Webhook needs to be generated.',
            deleteModalP3: '<strong>To confirm, enter the word "delete":</strong>',
            deleteModalPlaceholder: 'Type "delete"',
            deleteModalConfirmationWord: 'delete',
            delete: 'Delete',
          },
          whitelist: {
            title: 'IP Addresses',
            colIP: 'IP',
            colDescription: 'Description',
            colCreatedOn: 'Created On',
            addIp: 'Add IP Address',
            messageP1: `Safeguard your connections by managing your IP whitelist right here, ensuring secure and trusted access to our platform. The IP whitelist allows you to specify authorized IP addresses, providing an added layer of security to your interactions with our system.`,
            messageP2: `Manage and maintain control over your network's integrity and ensure only trusted sources can connect with confidence.`,
            newWebhook: 'New Webhook',
            cancel: 'Cancel',
            add: 'Add',
            save: 'Save',
          },
          actionConfirmationModal: {
            cancelButton: 'Cancel',
            confirmationButton: 'OK',
            typeDelete: 'Type "delete"',
          },
          newLayout: {
            createButton: 'Create Project',
            description1: 'To get started you will need to first create a ',
            description2: 'Project',
            description3: ', from the',
            description4: 'options below.',
          },
          adminLayout: {
            headerTitle: 'Staff Admin Dashboard',
            menuUsers: 'Partner Users',
            menuProducts: 'Products',
            menuProductUsers: 'Product Users',
            menuTransactions: 'Transactions',
            menuTransfers: 'Transfers',
          },
          adminPartnerUsers: {
            tableTitle: 'Partner Users',
            name: 'Name',
            partnerUserId: 'Partner User ID',
            email: 'Email',
            role: 'Role',
            creationDate: 'Creation Date',
            allRoles: 'All Roles',
            admin: 'Admin',
            user: 'User',
            owner: 'Owner',
            invited: 'Invited',
            allTimes: 'All Times',
          },
          createProject: {
            buttonText: 'Get Started',
            inputLabel: 'Project Name',
            dropdownLable: 'Project Type',
            description1: 'To get started you will need to first create a ',
            description2: 'Project',
            description3: ', from the',
            description4: 'options below.',
            digitalWallet: 'Digital Wallet',
            cardIssuing: 'Card Issuing',
            hybridLoop: 'Hybrid Loop',
          },
          dashboard: {
            processTitle:
              'Your project’s financial ecosystem is being created. This should take less than 1 min. If this is taking more than 5 minutes, please contact support.',
            errorTitle:
              'Something has gone wrong in creating this project, please contact support.',
            welcomeTitle: 'Welcome to Lynk',
            glanceText: 'At a Glance',
            startTitle: 'Getting Started',
            step1Title: 'Step 1',
            step2Title: 'Step 2',
            step3Title: 'Step 3',
            step4Title: 'Step 4',
            noRows: 'You do not have any payment types added yet',
            addPaymentTypes: 'Add Payment Type',
            step1Description: 'Explore the Dashboard',
            step2Description: 'Review Docs & Guides',
            step3Description: 'Create API Token',
            step4Description: 'Experiment with Lynk',
            colId: 'Payment ID',
            colAmount: 'Amount',
            colAccountId: 'Linked Account ID',
            colDate: 'Date',
            colType: 'Type',
            colUserId: 'User ID',
            colDescription: 'Description',
            colStatus: 'Status',
            noTransactionFound: 'You do not have any transactions yet',
            searchPlaceholder: 'Search Transactions',
            tableTitle: 'Transactions',
            csvFileName: 'product_transactions',
            merchantName: 'Merchant Name',
            merchantMid: 'Merchant ID',
            merchantMcc: 'Merchant Category',
            merchantCity: 'Merchant City',
            merchantState: 'Merchant State',
            merchantPostalCode: 'Merchant Postal code',
            merchantCountry: 'Merchant Country',
            demoAlertTitle: 'For Demo Purposes Only',
            demoAlertMessage:
              'We are not showing data related to any existing accounts. To continue the setup please verify your business. ',
            demoAlertMessageRejected:
              'Your business has not been approved and we are not showing data related to your account. Contact our support team for more information.',
            demoAlertMessagePendingVerification:
              'We are not showing data related to any existing accounts. Your information will appear here, once your account has been verified and approved. ',
            contactSupport: 'Contact Support',
            learnMore: 'Learn more',
            verifyLater: 'Verify Later',
            verifyNow: 'Verify Now',
            businessVerificationTitle: 'Business Verification Needed',
            businessVerificationMessage1:
              'To access this feature your business has to be  verified and approved.',
            businessVerificationMessage2:
              'For the Business Verification Progress you need to provide us with additional information about your company, incorporation type and your identity.',
            later: 'Later',
            welcomeKartera: 'Welcome to Kartera!',
            informationDialogP1:
              'Explore the possibilities with the demo dashboard and get a glimpse of what Kartera offers. To unlock the full potential, verify your account today.',
            informationDialogP2Title: 'Massive Savings on Fee Processing',
            informationDialogP2:
              'Seamlessly collect instant or recurring payments with up to 90% fee savings. Say goodbye to costly card fees and embrace a secure payment solution.',
            informationDialogP3Title: 'Increased AOV & Retention',
            informationDialogP3:
              'Use the fee savings to create irresistible loyalty programs. Increase your Average Order Value (AOV) by up to 48% and enjoy customer return rates surging by up to 83%.',
            informationDialogP4Title: 'Instant Access to Capital',
            informationDialogP4:
              'Payments clear instantly, allowing merchants to access their funds faster and improve their ROI.',
            informationDialogP5:
              "Choose to verify now or later. Completing the process ensures security and compliance, unlocking all the benefits of Kartera's Pay by Bank.",
          },
          graphs: {
            newCustomers: 'New Customers',
            hintNewCustomers:
              'Number of new customers created with connected bank accounts. Does not include guest customers.',
            hintTotalCashBack:
              'Estimated gross value of all current Cash back available in customer rewards wallets.',
            hintTotalPoints:
              'Estimated gross value of rewards points available in customer rewards wallets.',
            hintTotalPayments:
              'Estimated gross value of all current Payments available in customer wallets.',
            hintTotalWithdraws: 'Estimated gross value of all Withdraws made in customer wallets.',
            averageSpend: 'Average Spend',
            hintAverageRefunds:
              'Estimated average of all refunds that are in the “Completed” state.',
            hintAverageWithdraws:
              'Estimated average of all refunds that are in the “Withdraw” state.',
            hintAverageSpend:
              'Estimated revenue for all payments created with a customer, divided by the total number of customers with payments.',
            dispute: 'Dispute Activity',
            hintDispute:
              'Estimated number of disputes divided by the number of successful payments in the same time series.',
            errorMessage:
              'Something went wrong. There was an issue loading the content of this card.',
            reload: 'Reload',
            contactSupport: 'Contact Support',
          },
          rewards: {
            setupDialogTitle: 'Setup your rewards program',
            setupDialogP1:
              'Take advantage of the opportunity to choose the type of rewards that align perfectly with your business and goals.',
            setupDialogP2:
              'Whether you opt for "Instant Discount" to provide your users with immediate savings or "Cash Back Credit" to allow them to accumulate rewards over time, this critical decision can drive your growth and unlock incredible potential uplift.',
            setupDialogP3: 'Start maximizing your rewards today!',
            learnMore: 'Learn More',
            later: 'Later',
            cancel: 'Cancel',
            back: 'Back',
            discard: 'Discard',
            next: 'Next',
            finish: 'Finish',
            setupNow: 'Setup Now',
            active: 'Active',
            inactive: 'Inactive',
            expirationDuration: 'Expiration Duration',
            days: 'days',
            months: 'Months',
            moreInformation: 'More Information',
            TypeOfRewardDialogTitle: 'Choose your type of rewards',
            TypeOfRewardDialogText:
              'Choose the rewards that align best with your business: "Instant Discount" for immediate savings or "Cash Back Credit" for accumulating rewards over time.',
            instantDiscount: 'Instant Discount',
            instantDiscountP1:
              'Best for low-frequency transactions such as high-value e-commerce, seasonal purchases, annual subscriptions, or companies that see one transaction every two weeks per user on average.',
            instantDiscountP2:
              'Customers are presented with a discount amount that is applied to their transaction, for example a $150 discount on the $1,500 purchase of a new MacBook. The final amount of their purchase will now be $1,350.',
            cashBackCredit: 'Cash Back Credit',
            cashBackCreditP1:
              'Best suited for high-frequency transaction platforms like food delivery, gig economy, online gaming, ride-hailing services, etc. who also want to encourage customer retention and loyalty.',
            cashBackCreditP2:
              'With every purchase, customers receive funds in their digital wallet that can be used on future transactions e.g. when buying food at McDonalds for $20 they get $1 in cash back credit for future transactions.',
            pointsSystem: 'Points System',
            pointsSystemP1:
              'Similar to Cash Back Credit, best suited for high-frequency transaction platforms to encourage customer retention and loyalty like food delivery, gig economy, online gaming or ride-hailing services.',
            pointsSystemP2:
              'With every purchase, customers receive points in their digital wallet, e.g. when spending $10 on Uber they earn 5pts and, once more points have been collected, 100pts can be used to reduce a future Uber ride by $1.',
            instantDiscountTitle: 'Set up Instant Discount',
            instantDiscountSubtitle: 'Instant Discount Percentage',
            instantDiscountText:
              'You have the flexibility to determine the percentage (ranging from 0 to 5%) that will be allocated towards the instant discount, allowing you to customize the impact of the program and maximize its effectiveness for your business and your users.',
            cashBackCreditTitle: 'Set up Cash Back Credit',
            cashBackCreditSubtitle: 'Cash Back Percentage',
            cashBackCreditText:
              'You have the flexibility to determine the percentage (ranging from 0 to 2) that will be allocated towards the cash back credits, allowing you to customize the impact of the program and maximize its effectiveness for your business and your users.',
            cashBackBreakage: 'Cash Back Breakage',
            cashBackBreakageText:
              'Allocated cash back credits will expire after the set duration if breakage is active. The duration can be set to any amount of months.',
            pointsTitle: 'Set up Points System',
            pointsCollection: 'Points Collection',
            pointsCollectionDescription:
              'Define the amount of points a user will receive per $1 spend on a transaction.',
            everySpent: 'Every $1 spent',
            earnMultiple: 'Earn multiple',
            earnExtra: 'Earn extra',
            minimunTransaction: 'Minimum transaction value to receive this offer:',
            noMinimum: 'No minimum transaction value to receive the offer',
            point: 'point(s)',
            pointsRedemption: 'Points Redemption',
            pointsRedemptionDescription:
              'Define the amount of points needed for a $1 reduction on a transaction.',
            reduction: 'reduction',
            exampleAlertTitle: 'Example (based on above settings)',
            exampleAlertBody: `A customer will receive {{collect}} pts for a $100 transaction. 
            These {{collect}} pts equal a {{redeem}} discount on future purchases.\n         
            Assuming a standard 30% breakage, the value of {{collect}} pts becomes {{breakage}}.\n           
            Assuming a standard 43% uplift, a next transaction will be $143 and, with the collected {{collect}} pts utilized, will generate <strong>{{revenue}} in gross {{revenue_loss}}</strong>.`,
            messageAvoidLoss:
              'To avoid this loss, decrease the amount of points collected and/or increase the amounts needed to redeem.',
            pointsErrorMessage:
              'Enter valid data above to see an example calculation for the Points System.',
            mandatoryField: 'This field is mandatory',
            numericError: 'Enter a numeric value, for example 10',
            zeroError: 'Enter a value that is greater than 0',
            floatError: 'Enter a whole number, for example 10',
            maximumError: 'Amount should be smaller than 1000',
            maximumDaysError: 'Amount greater than the limit',
            introductoryOffer: 'Introductory Offer',
            introductoryOfferType: 'Introductory Offer Type',
            introductoryOfferDescription:
              'Allow customers to earn extra points or receive multiple points (up to 10x) on their first transaction. Adding the introductory offer enhances the initial customer experience and creates an additional incentive.',
            successMessage: '"{{type}}" with {{percentage}}% is now activate.',
            successUpdateMessage: 'Reward updated successfully.',
            successPointSystemMessage:
              'Reward point system is activated at {{amount}}pts(earning ratio) per dollar',
            pointsCollectionLabel: '{{points}} pt for every $1 spent',
            pointsRedemptionLabel: '$1 reduction for {{points}} pts',
            introductoryOfferLabel: 'Earn {{points}}x points (for transactions above ${{value}})',
            errorNegativeAmount: 'Negative numbers are not valid',
            errorValidAmount: 'Only numeric values are accepted',
          },
          paymentWidget: {
            pageTitle: 'Payment Widget',
            panelTitle: 'Streamline your payment process with Kartera',
            panelP1: `Kartera's payment widget enhances your e-commerce platform or marketplace with a <strong>seamless and secure payment experience for reduced integration complexity</strong> and enhanced security.`,
            panelP2:
              'Our secure and efficient payment flow includes <strong>user account management, bank linking, payment initiation, double authorization, and transaction completion</strong>.',
            panelP3:
              'Additionally, it provides customizable branding, layout options, and light/dark mode settings to suit all your needs.',
            panelP4:
              'Discover the power of the Kartera Payment Widget and unlock new possibilities for your business.',
            learnMoreButton: 'Lean more about the Payment Widget',
            panelButton: 'Setup Payment Widget',
            customizePanelTitle: 'Customize Kartera Payment Widget',
            customizePanelDescription: `Adjust the appearance of the widget: Change the image, title, and styles to match your brand's aesthetics, ensuring a cohesive and visually appealing payment experience at checkout.`,
            light: 'Light',
            dark: 'Dark',
            upload: 'Upload',
            theme: 'Theme',
            image: 'Image',
            title: 'Title',
            reset: 'Reset to Default',
            themeKartera: 'Kartera',
            themeFire: 'Fire',
            themeOcean: 'Ocean',
            themeNeutral: 'Neutral',
            themeMinimalist: 'Minimalist',
            themeSunset: 'Sunset',
            defaultTitle: 'Direct Bank Payment',
            charLeft: 'characters left',
            showSavings: 'Show Savings',
            allowCashBack: 'Allow Cash Back credit',
            poweredByKartera: `“Powered by Kartera”`,
            upgradeYourAccount: '<strong>Upgrade your account</strong> to hide label',
            cancel: 'Cancel',
            save: 'Save',
            authorizePayment: 'Authorize Payment',
            toggleMode: 'Toggle between the modes',
            defaultText: 'Save <strong>$1.36</strong> when paying with your bank account.',
            cashBackText: 'Use $11.49 from Cash Back credit',
            SIZEErrorTitle: 'File size too large',
            SIZEErrorText: 'Choose an image file smaller than 2 MB.',
            DIMENSIONErrorTitle: 'Image is too small',
            DIMENSIONErrorText: 'Choose an image with minimum 22x22px dimensions.',
            FORMATErrorTitle: 'Invalid file format',
            FORMATErrorText:
              'Only JPG, PNG, BMP, and TIFF files are accepted formats for the badge image.',
            UNKNOWNErrorTitle: 'Upload Failed',
            UNKNOWNErrorText: 'Unknown error occurred during the upload process. Please try again.',
          },
          platforms: {
            platforms: 'Platforms',
            unconnectedPanelTitle: 'Connect your commerce platforms with Kartera',
            unconnectedPanelP1:
              'Our payment plugin effortlessly integrates with Shopify and WooCommerce, allowing you to accept Pay by Bank payments and reduce card fees by up to 90%.',
            unconnectedPanelP2:
              'With our built-in loyalty and rewards system, you can boost your average order value by up to 48% and achieve return rates as high as 83%.',
            unconnectedPanelP3:
              'Easily manage rewards, refunds, and chargebacks, and gain valuable insights with real-time analytics.',
            unconnectedPanelP4:
              'Discover the power of Kartera Plugins and unlock new possibilities for your business.',
            learnMoreButton: 'Lean more about Kartera Plugins',
            customizeTitle: 'Customize the “Pay by Bank” plugin',
            customizeText: `Adjust the appearance of "Pay By Bank" buttons and badges for your store using the editor below. Change the image, title, and styles to match your brand's aesthetics, ensuring a cohesive and visually appealing payment experience at checkout.`,
            normalButton: 'Normal Button',
            tinyButton: 'Tiny Button',
            style: 'Style',
            image: 'Image',
            upload: 'Upload',
            white: 'White',
            green: 'Green',
            black: 'Black',
            title: 'Title',
            charLeft: 'characters left',
            poweredByKartera: `“Powered by Kartera”`,
            toggleMessageTypes: 'Toggle between the message types',
            reset: 'Reset to Default',
            cashBack: 'Cash Back',
            discount: 'Discount',
            points: 'Points',
            noRewards: 'None',
            cancel: 'Cancel',
            save: 'Save',
            confirm: 'Confirm',
            connectNow: 'Connect Now',
            upgradeYourAccount: '<strong>Upgrade your account</strong> to hide label',
            payByBank: 'Pay by Bank',
            SIZEErrorTitle: 'File size too large',
            SIZEErrorText: 'Choose an image file smaller than 2 MB.',
            DIMENSIONErrorTitle: 'Image is too small',
            DIMENSIONErrorText: 'Choose an image with minimum 22x22px dimensions.',
            FORMATErrorTitle: 'Invalid file format',
            FORMATErrorText:
              'Only JPG, PNG, BMP, and TIFF files are accepted formats for the badge image.',
            UNKNOWNErrorTitle: 'Upload Failed',
            UNKNOWNErrorText: 'Unknown error occurred during the upload process. Please try again.',
            connectDialogTitle: 'Connect {{ platform }} with Kartera',
            connectDialogP1:
              'Our payment plugin effortlessly integrates with {{ platform }}, allowing you to accept Pay by Bank payments and <strong>reduce card fees by up to 90%.</strong>',
            connectDialogP2:
              'With our built-in loyalty and rewards system, you can <strong>boost your average order value by up to 48% and achieve return rates as high as 83%.</strong>',
            connectDialogP3:
              'Easily manage rewards, refunds, and chargebacks, and gain valuable insights with real-time analytics.',
            connectDialogP4:
              'Discover the power of Kartera Plugins and unlock new possibilities for your business.',
            learnMoreKartera: 'Learn more about Kartera Plugins',
            confirmDialogP1:
              'By enabling Kartera as a payment method, you empower customers to enjoy a seamless and secure checkout process, facilitating transactions with ease while reducing card fees by up to 90%.',
            confirmDialogP2:
              'Make the most of your {{ platform }} store and provide a delightful shopping journey with personalized messaging powered by Kartera by integrating Kartera messaging.',
            confirmDialogP3:
              'Foster confidence and convenience, by letting customers know they can rely on trusted payment method. Enhance the visibility of Kartera and encourage seamless transactions in your {{ platform }} store.',
            confirmDialogButton1: 'Enable Kartera as a payment method',
            confirmDialogButton2: 'Add Kartera messaging to your {{ platform }}',
            confirmDialogButton3: 'Add the Kartera payment icon',
          },
          thirdParty: { title: '3rd Party' },
          reports: {
            reports: 'Reports & Analytics',
            yourPerformance: 'Your Performance',
            report: 'Report',
            balance: 'Balance',
            totalBalance: 'Total Balance',
            availableBalance: 'Available Balance',
            revenue: 'Revenue',
            totalRevenueGenerated: 'Total Revenue Generated',
            convertedFromBreakage: 'Converted from Breakage',
            rewards: 'Rewards',
            totalEarned: 'Total Earned',
            totalUsed: 'Total Used',
            topCustomers: 'Top Customers',
            balanceHint:
              'Currently held (total) and settled (available) balance of this account. All numbers are minus processing fee.',
            revenueHint:
              'Total revenue generated and revenue converted from unclaimed or expired rewards (breakage). All numbers are minus processing fee.',
            rewardsHint:
              'Total value of all rewards earned and used by users in the selected timeframe.',
            rewardsPointsHint:
              'Total value (and amount) of all rewards points earned and used by users in the selected timeframe.',
            topCustomersHint:
              'Top 10 customers transactions in this timeframe sorted by amount (highest first).',
            disabledText: `No data available
for selected time frame`,
          },
          adminDashboard: {
            welcomeAdmin: 'Welcome {{ admin }}!',
            partnerName: 'Partner',
            partnerId: 'PartnerID',
            country: 'Country',
            stateProvince: 'State / Province',
            creationDate: 'Creation Date',
            status: 'Status',
            tableTitle: 'Partners',
            csvFileName: 'partners',
            approved: 'Approved',
            pending: 'Pending',
            error: 'Error',
            rejected: 'Rejected',
            absent: 'Absent',
            expired: 'Expired',
            allStatuses: 'All Statuses',
            allCountries: 'All Countries',
            custom: 'Custom...',
          },
          transactions: {
            title: 'All Transactions',
            colAmount: 'Amount',
            colCardId: 'Card ID',
            colUserId: 'User ID',
            colDate: 'Date',
            colDescription: 'Description',
            colAccountId: 'Account ID',
            noTransactionFound: 'No Transactions yet',
            searchPlaceholder: 'Search Transactions',
            tableTitle: 'Transactions',
            csvFileName: 'product_transactions',
            merchantName: 'Merchant Name',
            merchantMid: 'Merchant ID',
            merchantMcc: 'Merchant Category',
            merchantCity: 'Merchant City',
            merchantState: 'Merchant State',
            merchantPostalCode: 'Merchant Postal code',
            merchantCountry: 'Merchant Country',
          },
          payments: {
            title: 'Payment Types',
            name: 'Name',
            account: 'Account',
            type: 'Type',
            singleAmount: 'Single Amount',
            monthlyRate: 'Monthly Rate',
            yearlyRate: 'Yearly Rate',
            status: 'Status',
            addPaymentType: 'Add Payment Type',
            active: 'Active',
            paused: 'Paused',
            completed: 'Completed',
            queued: 'Queued',
            inProgress: 'In Progress',
            returned: 'Returned',
            cancelled: 'Cancelled',
            singlePayment: 'Single',
            subscription: 'Subscription',
            singlePaymentSubnote: 'One-time payments with instant transfer',
            subscriptionSubnote:
              'Regular, recurring payments at specific intervals (monthly or yearly)',
            addPaymentName: 'Name:',
            addPaymentNamePlaceholder: `e.g. "Tier 1 - Basic"`,
            addPaymentDescription: 'Description:',
            addPaymentDescriptionPlaceholder: `Enter text describing the payment type ...`,
            cancel: 'Cancel',
            next: 'Next',
            add: 'Add',
            save: 'Save',
            paymentTypeLabel: 'Payment Type:',
            selectAccount: 'Select Account',
            detailsFor: `Details for “{{ payment }}”`,
            enterAmount: 'Enter Amount',
            totalForSinglePayment: 'Total for Single Payment:',
            delete: 'Delete',
            deleteDialog1:
              'Deleting the payment type will permanently remove it and make it unavailable for your users.',
            deleteDialog2:
              'If you might need this payment type in the future again, consider to <strong>pause the payment type</strong> instead.',
          },
          transfers: {
            title: 'Transfers',
            searchPlaceholder: 'Search transfers',
            colId: 'Transfer ID',
            colAccountId: 'Account ID',
            colCounterpartyId: 'Counterparty ID',
            colAmount: 'Amount',
            colService: 'Service',
            colStatus: 'Status',
            colCreatedAt: 'Created at',
            colUpdatedAt: 'Updated at',
            colDate: 'Date',
            standard: 'STANDARD',
            sameday: 'SAMEDAY',
            pending: 'PENDING',
            canceled: 'CANCELED',
            processing: 'PROCESSING',
            error: 'ERROR',
            sent: 'SENT',
            returned: 'RETURNED',
            statusInProgress: 'In Progress',
            statusInsufficientFunds: 'Insufficient Funds',
            statusRejected: 'Rejected',
            noTransferFound: 'No transfers yet',
            masterAccount: 'Rize Master Account',
            rewardAccount: 'reward Account',
            fromAcountDropdownLable: 'Select Account',
            newBalance: 'New Balance',
            memoPlaceholder: 'Enter memo here...',
            memoLabel: 'Transfer Memo',
            from: 'From',
            to: 'To',
            account: 'Account',
            amountPlaceholder: '$100,23',
            amount: 'Amount',
            transferFunds: 'Transfer Funds',
            csvFileName: 'product_transfers',
          },
          refunds: {
            title: 'Refunds',
            searchPlaceholder: 'Search transfers',
            date: 'Date',
            accountId: 'Account ID',
            linkedAccountId: 'Linked Account ID',
            customer: 'Customer',
            transactions: 'Transaction:',
            amount: 'Amount',
            colService: 'Service',
            status: 'Status',
            email: 'Email',
            completed: 'Completed',
            queued: 'Queued',
            inProgress: 'In Progress',
            returned: 'Returned',
            cancelled: 'Cancelled',
            createRefund: 'Create Refund',
            recentCustomers: 'Recent Customers:',
            noRefunds: 'You do not have any refunds yet',
            userId: 'User Id',
            refundAmount: 'Refund Amount',
            refundSentDate: 'Refund Sent',
            refundCancelledDate: 'Refund Cancelled',
            refundProcessedDate: 'Refund Processed',
            fee: 'Fee',
            refundSent: 'Refund Sent',
            transactionDate: 'Transaction Date',
            transactionAmount: 'Transaction Amount',
            transactionDescription: 'Transaction Descrtiption',
            error: 'ERROR',
            sent: 'SENT',
            statusInProgress: 'In Progress',
            statusInsufficientFunds: 'Insufficient Funds',
            statusRejected: 'Rejected',
            masterAccount: 'Rize Master Account',
            rewardAccount: 'reward Account',
            fromAcountDropdownLable: 'Select Account',
            newBalance: 'New Balance',
            memoPlaceholder: 'Enter memo here...',
            memoLabel: 'Transfser Memo',
            from: 'From',
            back: 'Back',
            to: 'To',
            account: 'Account',
            amountPlaceholder: '$100,23',
            transferFunds: 'Transfer Funds',
            csvFileName: 'product_transfers',
            close: 'Close',
            searchCustomerPlaceholder: 'Customer, Email or Account ID',
            noTransactionAvailable: 'No transaction available for refund.',
            amountForRefund: 'Amount for Refund:',
            transaction: 'Transaction:',
            next: 'Next',
            send: 'Send',
            amountError: 'Select or enter an amount for refund',
            processingTime: 'Processing time:',
            partialRefund: 'partial refund',
            refundTo: 'Refund to:',
            cashBackCredit: 'Cash Back Credit',
            bankAccountSelector: 'Bank Account <strong>(Original Payment Method)</strong>',
            bankAccount: '<strong>Bank Account</strong> (Original Payment Method)',
            fullRefund: 'full refund',
            paidByMerchant: 'paid by merchant',
            excludingWeekends: 'excluding weekends',
            successPanelTitle: 'Refund sent successfully',
            successPanelP1:
              'Your refund of <strong>{{amount}}</strong> USD to <strong>{{customer}}</strong> has been sent successfully.',
            successPanelP2Cashback:
              "The funds will be immediately deposited as Cash Back Credit in the customer's account.",
            successPanelP2Bank:
              'The funds will be deposited to the customer’s bank account within the next 12-48hrs.',
            errorPanelTitle: 'An error has occured',
            errorPanelP1: 'Refund has not been processed and no funds were sent to the customer.',
            errorPanelP2:
              'Please try again or reach out to us via email for support: <strong>support@kartera.com.</strong>',
            downloadStatement: 'Download Statement',
            tryAgain: 'Try Again',
            errorNoAmount: 'Enter an amount for refund',
            errorBiggerThanAmount: 'Value should be less or equal original amount',
            errorNegativeAmount: 'Value should be more than 0',
          },
          adminPartnerOverview: {
            approved: 'Approved',
            partnerId: 'Partner ID',
            location: 'Location',
            creationDate: 'Creation Date',
            description: 'Description',
            category: 'Category',
            url: 'URL',
            legalBusinessName: 'Legal Business Name',
            legalStructure: 'Legal Structure',
            incorporationDate: 'Incorporation Date',
            incorporationCountryIsoCode: 'Incorporation Country',
            incorporationStateProvinceCode: 'Incorporation State / Province',
            phoneNumber: 'Phone Number',
            physicalAddress: 'Physical Address',
            verificationStatus: 'Verification Status',
            emailAddress: 'E-Mail',
            contactInformation: 'Contact Information',
            legalInformation: 'Legal Information',
            billingInformation: 'Billing Information',
            billingSet: 'Billing set',
            billingNotSet: 'Billing not set',
            achStandardFee: 'ACH Standard Fee',
            achSameDayFee: 'ACH Same-Day Fee',
            kycBasic: 'KYC Basic',
            kycEnhanced: 'KYC Enhanced',
            saasFeeStarter: 'SaaS Fee (monthly) - Starter',
            saasFeeGrow: 'SaaS Fee (monthly) - Grow',
            saasFeeEnterprise: 'SaaS Fee (monthly) - Enterprise',
            edit: 'Edit',
            save: 'Save',
            cancel: 'Cancel',
            more: 'more',
            less: 'Less',
          },
          adminPartnerProducts: {
            status: 'Status',
            tableTitle: 'Products',
            csvFileName: 'partners',
            ready: 'Ready',
            pending: 'Pending',
            error: 'Error',
            rejected: 'Rejected',
            absent: 'Absent',
            expired: 'Expired',
            allStatuses: 'All Statuses',
            productName: 'Product Name',
            productId: 'Product ID',
            creationDate: 'Creation Date',
            ownerHolder: 'Owner as Cardholder',
            yes: 'Yes',
            no: 'No',
            cardAuthorization: 'Card Authorization Request',
            active: 'Active',
            inactive: 'Inactive',
            failure: 'Failure',
            success: 'Success',
            defaultValue: 'Default Value',
            cardTitile: 'Card Product',
            cardChangeDescription: 'This design is shown to all users for',
            changeText: 'Change',
            cardsTitle: 'Change Card Product',
            cardsdescription1: 'Select the design that will be shown to users for ',
            cardsdescription2:
              'When changing the card product, all new cards going forward will be created with the new design (previous cards still work as normal). ',
            selected: 'Selected',
            changetitle: 'Change Card Product?',
            changeDescription1:
              'Changing the card product will affect all new cards going forward and will affect all users. ',
            changeDescription2: 'Previously created cards will continue to work as normal.',
          },
          adminProductUsers: {
            title: 'Product Users',
            colId: 'Id',
            colType: 'Type',
            colCreatedAt: 'Created At',
            colName: 'Name',
            colEmail: 'Email',
            colLastActive: 'Last Active',
            colStatus: 'Status',
            statusActive: 'Active',
            statusInProgress: 'In Progress',
            statusRefunded: 'Refunded',
            statusRejected: 'Rejected',
            noUserFound: 'No users yet',
            csvFileName: 'product_users',
            allStatus: 'All Statuses',
            allTimes: 'All Time',
            name: 'Name',
            email: 'Email',
            productUserId: 'Product User Id',
          },
          adminProductTransactions: {
            title: 'Transactions',
            allTimes: 'All Times',
            allTypes: 'All Types',
            date: 'Date',
            ach: 'ACH',
            type: 'Type',
            user: 'User',
            account: 'Account',
            card: 'Card',
            amount: 'Amount',
            deposit: 'Deposit',
            invoice: 'Invoice',
            withdrawal: 'Withdrawal',
            payment: 'Payment',
          },
          adminProductTransfers: {
            title: 'Transfers',
            searchPlaceholder: 'Search transfers',
            colId: 'Transfer ID',
            colAccountId: 'Account ID',
            colCounterpartyId: 'Counterparty ID',
            colAmount: 'Amount',
            colService: 'Service',
            colStatus: 'Status',
            colCreatedAt: 'Created at',
            colUpdatedAt: 'Updated at',
            colDate: 'Date',
            standard: 'STANDARD',
            sameday: 'SAMEDAY',
            pending: 'PENDING',
            canceled: 'CANCELED',
            processing: 'PROCESSING',
            error: 'ERROR',
            sent: 'SENT',
            returned: 'RETURNED',
            statusInProgress: 'In Progress',
            statusInsufficientFunds: 'Insufficient Funds',
            statusRejected: 'Rejected',
            noTransferFound: 'No transfers yet',
            masterAccount: 'Rize Master Account',
            rewardAccount: 'reward Account',
            fromAcountDropdownLable: 'Select Account',
            newBalance: 'New Balance',
            memoPlaceholder: 'Enter memo here...',
            memoLabel: 'Transfer Memo',
            from: 'From',
            to: 'To',
            account: 'Account',
            amountPlaceholder: '$100,23',
            amount: 'Amount',
            transferFunds: 'Transfer Funds',
            csvFileName: 'product_transfers',
            allStatus: 'All Statuses',
          },
          otp: {
            incorrect: 'OTP Code is incorrect. Try again.',
            remaining: 'remaining',
            secondsRemaining: 'seconds remaining',
            codeExpired: 'OTP code is expired.',
            notRecieved: 'Did not receive a code?',
            codeSentAgain: 'Code sent again! Not working?',
            requestAnother: 'Resend',
            requestNewCode: 'Request New Code',
            verifyButton: 'Verify Code',
            contactSupport: 'Contact Support',
          },
        },
      },
    },
  });

export default i18n;
