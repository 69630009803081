import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { TbArrowsDownUp } from 'react-icons/tb';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { KarteraTableFooter } from './tableFooter';
import { KarteraTableToolbar } from './tableToolbar';
import { KarteraSelectField } from '../selectField';
import { KarteraChip } from '../chip';
import { FilterMenu } from '../../filterMenu';
import { FilterRowType } from '../../filterMenu/filterRow/types';
import { formatDate, handlePeriod, truncateText } from '../../../utils/helperFunctions';
import mccCodes from '../../../consts/mccCodes.json';
import { color } from '../../../consts/ColorConst';
import { MerchantType, PageStateType, QuickFilterItem, TableProps } from './types';
import { KarteraCheckbox } from '../checkbox';
import { useDebounce } from '../../../hooks/Debounce';

type StyleProps = {
  clickable: boolean;
};
type SimpleFilterTyupe = {[key: string]: string};

const useStyles = makeStyles<any, StyleProps>((theme: any) => ({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 1180,
    borderRadius: 6,
    backgroundColor: color.WHITE_01,
    overflow: 'hidden',
    boxShadow: theme.shadow.elevation4Grey,
  },
  chipContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '10px',
    padding: '0 12px',
    gap: 6,
  },
  root: {
    position: 'relative',
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
    '& .MuiDataGrid-row': {
      maxHeight: '34px !important',
      minHeight: '34px !important',
      borderBottom: '1px solid transparent !important',
      cursor: ({ clickable }) => (clickable ? 'pointer' : 'default'),
    },
    '& .MuiDataGrid-overlay': {
      '& .MuiCircularProgress-root': {
        color: color.GREEN_LIGHT_2,
      },
    },
    '& .Mui-selected': {
      backgroundColor: `${color.GREEN_LIGHT_5} !important`,
      borderBottom: `1px solid ${color.GREEN_LIGHT_2} !important`,
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid transparent !important',
      color: color.GREY_DARK_2,
      fontSize: 14,
      fontWeight: 400,
      '&:focus': {
        outline: `transparent`,
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      borderRadius: 0,
      backgroundColor: color.GREY_LIGHT_5,
      border: 0,
      borderTop: `1px solid ${color.GREY_LIGHT_3}`,
      borderBottom: `1px solid ${color.GREY_LIGHT_3}`,
      height: '34px',
      minHeight: '34px !important',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: color.GREY_DARK_2,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  noRows: {
    position: 'absolute',
    top: '54%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '14px',
    padding: '10px',
    color: color.GREY,
    backgroundColor: color.WHITE_01,
    zIndex: 9,
  },
  dateWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
}));

const CHIP_MAX_TEXT_LENGTH = 40;

export const KarteraTable: FC<TableProps> = ({
  title,
  columns,
  selectedItems,
  setSelectedItems,
  refreshDate,
  fetchData,
  fetchSearchData,
  defaultPageSize = 10,
  minHeight = '260px',
  height = '496px',
  paginationAndSortingMode = 'server',
  searchPlaceholder,
  rowsPerPageOptions = [5, 10, 25, 100],
  csvFileName,
  quickFilters,
  supressToolbar,
  supressFooter,
  customStyle,
  idModification = false,
  filterFields,
  filteringEnabled = true,
  component,
  rows,
  noRowsMessage,
  refreshTable,
  ...rest
}) => {
  const classes = useStyles({ clickable: rest.onRowClick ? true : false });
  const chipContainerRef = useRef<HTMLDivElement>(null);

  const [currentSortingField, setCurrentSortingField] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const [pageState, setPageState] = useState<PageStateType>({
    total: 0,
    page: 0,
    pageSize: defaultPageSize,
    sortField: 'created_at',
    sortDirection: 'DESC',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [fields, setFields] = useState<QuickFilterItem[]>([]);
  const [filters, setFilters] = useState<FilterRowType[]>([]);
  const [selectedQuickFilters, setSelectedQuickFilters] = useState<SimpleFilterTyupe>();
  const [visibleChips, setVisibleChips] = useState<ReactNode[]>([]);
  const [hiddenChipsCounter, setHiddenChipsCounter] = useState(0);

  const debouncedSearchValue = useDebounce(searchValue);

  function formatFiltersToAPISorting() {
    const result: {[key: string]: string} = {};
    filters.forEach( filter => {
      if (filter.fieldName && filter.operatorValue) {
        if (filter.operatorValue === 'equals') {
          result[filter.fieldName] = filter.fieldValue;
        } else if (filter.operatorValue === 'between') {
          result['date_from'] = filter.fromDate?.format('YYYY-MM-DD') || '';
          result['date_to'] = filter.toDate?.format('YYYY-MM-DD') || '';
        }
      }
    });
    return result;
  }

  async function getAsyncData() {
    if (!fetchData) return;
    try {
      setIsLoading(true);
      const filters = formatFiltersToAPISorting();
      if (debouncedSearchValue) filters.all = debouncedSearchValue; 
      
      const response = await fetchData(pageState, { ...filters, ...selectedQuickFilters});
      if (!response?.data) {
        setFilteredRows([]);
        setPageState((state) => ({ ...state, total: 0 }));
        return;
      }
      const data: any[] = response.data;
      const total = response.metadata.total_elements || response.data.length || 0;
      
      setFilteredRows(data);
      setPageState((state) => ({ ...state, total }));
    } catch (error) {
      //handle Error
    } finally {
      setIsLoading(false);
    }
  }
  
  async function handleApplyFilters(currentFilters: FilterRowType[]) {
    handleChips(currentFilters);
    setFilterMenuOpen(false);
    getAsyncData();
  }

  async function handleRemoveFilter(indexToRemove: number) {
    const newFilters = filters.filter((_, index) => index !== indexToRemove);
    setFilters(newFilters);
    handleChips(newFilters);
    await getAsyncData();
  }

  async function handleQuickFilter(field: string, item: string, type: string) {
    const newFilter: SimpleFilterTyupe = {};
    const currentQuickFilters = selectedQuickFilters;

    if (type === 'PERIOD') {
      if (item.toLowerCase().includes('all') && currentQuickFilters?.date_from && currentQuickFilters?.date_to) {
        delete currentQuickFilters.date_from;
        delete currentQuickFilters.date_to;
      } else {
        const [from, to] = handlePeriod(item.toLowerCase());
        newFilter['date_from'] = formatDate(from);
        newFilter['date_to'] = formatDate(to);
      }
    } else if (type === 'STATUS') {
      if (item.toLowerCase().includes('all') && currentQuickFilters && currentQuickFilters[field]) {
        delete currentQuickFilters[field];
      } else {
        newFilter[field] = item;
      }
    }

    setSelectedQuickFilters({...currentQuickFilters, ...newFilter});
  }

  function handleChips(currentFilters: FilterRowType[]) {
    const validFilters = currentFilters.filter((item) => item.fieldName !== '');
    const chips = validFilters.map((item) => {
      if (
        ['date', 'time', 'created_at', 'updated_at', 'execution_timestamp'].includes(
          item.fieldName.toLocaleLowerCase(),
        )
      ) {
        return (
          <>
            {item.fromDate?.format('YYYY-MM-DD') === item.toDate?.format('YYYY-MM-DD') ? (
              <>
                {`${item.fieldLabel}: `}
                <strong>{item.fromDate?.format('YYYY-MM-DD')}</strong>
              </>
            ) : (
              <div className={classes.dateWrapper}>
                {`${item.fieldLabel}: `}
                <strong>{item.fromDate?.format('YYYY-MM-DD')}</strong>
                <span>to</span>
                <strong>{item.toDate?.format('YYYY-MM-DD')}</strong>
              </div>
            )}
          </>
        );
      }
      const truncatedText = truncateText(
        `
        ${item.fieldLabel}: ${item.fieldValue}
      `,
        CHIP_MAX_TEXT_LENGTH,
      ).split(': ');
      return (
        <>
          {`${truncatedText[0]}: `}
          <strong>{truncatedText[1]}</strong>
        </>
      );
    });

    if (chipContainerRef?.current) {
      const outerWidth = chipContainerRef.current.clientWidth;
      const allowedVisibleChips = Math.floor(outerWidth / 200);
      setVisibleChips(chips.slice(0, allowedVisibleChips));
      setHiddenChipsCounter(chips.length - allowedVisibleChips);
    }
  }

  function handleRowSelection(selectionModel: GridSelectionModel) {
    setSelectedItems(selectionModel);
  }

  function handleSort(model: GridSortModel) {
    if (!model || model.length === 0) return;
    setPageState((state) => ({
      ...state,
      sortField: model[0].field,
      sortDirection: model[0].sort?.toUpperCase() as 'ASC' | 'DESC',
    }));
  }

  function handleCSVFileAndExport() {
    if (!filteredRows?.length) return;

    let fileContent = '\ufeff';
    const visibleColumns = columns.filter((col) => !col.hide);
    fileContent += visibleColumns.map((col) => col.headerName).join(';') + '\r\n';

    filteredRows.forEach((row) => {
      visibleColumns.forEach((col) => {
        const fieldName = col.field;
        if (fieldName.includes('merchant')) {
          const merchant: MerchantType = row['merchant'];
          switch (fieldName) {
            case 'merchant_mid':
              fileContent += merchant.mid;
              break;
            case 'merchant_name':
              fileContent += merchant.name;
              break;
            case 'merchant_mcc':
              //eslint-disable-next-line
              //@ts-ignore-next-line
              fileContent += mccCodes[merchant.mcc]?.edited_description || '';
              break;
            case 'merchant_city':
              fileContent += merchant.address.city;
              break;
            case 'merchant_state':
              fileContent += merchant.address.state;
              break;
            case 'merchant_postal_code':
              fileContent += merchant.address.postal_code;
              break;
            case 'merchant_country':
              fileContent += merchant.address.country;
              break;
          }
        }
        if (fieldName.includes('status') && csvFileName?.includes('transactions')) {
          const STATUS_TEXT:any = {
            COMPLETED: 'Completed',
            PROCESSED: 'Completed',
            QUEUED: 'Queued',
            INITIATED: 'Queued',
            IN_PROGRESS: 'In Progress',
            CANCELLED: 'Cancelled',
            RETURNED: 'Returned',
            ACTIVE: 'Active',
            PAUSED: 'Paused',
          };
          fileContent += STATUS_TEXT[row[fieldName]];
        } else {
          fileContent += row[fieldName] || '';
        }
        fileContent += ';';
      });
      fileContent += '\r\n';
    });

    const file = new Blob([fileContent], { type: 'text/csv;charset=UTF-8' });
    const fileName = `${csvFileName || 'link'}_${new Date().getTime()}.csv`;
    const fileLink = document.createElement('a');
    fileLink.href = URL.createObjectURL(file);
    fileLink.download = fileName;
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  }

  useEffect(() => {
    if (filterFields) {
      setFields(filterFields);
      return;
    }
    const fields: any = [];
    columns.map((item) => {
      if (item.headerName) {
        fields.push({ text: item.headerName, value: item.field });
      }
    });
    setFields(fields);
  }, [columns]);

  useEffect(() => {
    getAsyncData();
  }, [
    pageState.page,
    pageState.pageSize,
    pageState.sortField,
    pageState.sortDirection,
    filters,
    debouncedSearchValue,
    selectedQuickFilters,
    refreshDate,
    refreshTable,
  ]);

  return (
    <Box className={classes.container} sx={{ height: pageState.total === 0 ? minHeight : height }}>
      <FilterMenu
        filterMenuOpen={filterMenuOpen}
        setFilterMenuOpen={setFilterMenuOpen}
        fields={fields}
        filters={filters}
        setFilters={setFilters}
        onApplyFilters={handleApplyFilters}
      />
      {!supressToolbar && (
        <KarteraTableToolbar
          totalSelectedElements={selectedItems.length}
          searchPlaceholder={searchPlaceholder}
          totalElements={filteredRows?.length || 0}
          currentField={currentSortingField}
          counterTitle={title}
          handleExportData={csvFileName ? handleCSVFileAndExport : undefined}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          quickFilter={quickFilters?.map((filter, index) => (
            <KarteraSelectField
              key={`quickfilter-${index}`}
              defaultValue={filter.defaultValue}
              width={filter.width || '120px'}
              items={filter.items}
              placeholder={filter.defaultValue}
              onChange={(e: any) => {
                if (filter.field && e.target.value && filter.type) {
                  handleQuickFilter(filter.field, e.target.value, filter.type);
                }
              }}
            />
          ))}
          handleFilterData={() => setFilterMenuOpen(true)}
          filterCounter={filters.length}
          filteringEnabled={filteringEnabled}
          component={component}
        />
      )}
      {filters && visibleChips.length > 0 && (
        <Box ref={chipContainerRef} className={classes.chipContainer}>
          {visibleChips.map((item, index) => (
            <KarteraChip
              key={index}
              component={item}
              onClick={() => setFilterMenuOpen(true)}
              onDelete={() => handleRemoveFilter(index)}
              size='small'
            />
          ))}
          {hiddenChipsCounter > 0 && (
            <KarteraChip text={`+ ${hiddenChipsCounter} more filters`} size='small' />
          )}
        </Box>
      )}
      <DataGrid
        disableColumnSelector
        density='compact'
        classes={{ root: classes.root }}
        sx={{
          border: 'none',
          ...customStyle,
        }}
        sortingMode={paginationAndSortingMode}
        paginationMode={paginationAndSortingMode}
        columns={columns}
        rows={filteredRows.map((item, index) => {
          if (idModification) {
            return { ...item, id: index };
          } else {
            return item;
          }
        })}
        rowCount={pageState.total}
        loading={isLoading}
        page={pageState.page}
        pageSize={pageState.pageSize}
        onPageChange={(page) => setPageState((old) => ({ ...old, page }))}
        onPageSizeChange={(pageSize) => setPageState((old) => ({ ...old, pageSize }))}
        disableColumnMenu
        showColumnRightBorder={false}
        onColumnHeaderClick={(e: any) => setCurrentSortingField(e.field)}
        onSelectionModelChange={handleRowSelection}
        components={{
          BaseCheckbox: KarteraCheckbox,
          ColumnUnsortedIcon: () => <TbArrowsDownUp color={color.SECONDARY_DARK_02} />,
          Footer: () => {
            if (supressFooter) return null;
            return (
              <KarteraTableFooter
                setPageSize={(pageSize: number) => setPageState((old) => ({ ...old, pageSize }))}
                pageSizeOptions={rowsPerPageOptions}
              />
            );
          },
        }}
        onSortModelChange={handleSort}
        disableSelectionOnClick
        {...rest}
      />
      {pageState.total === 0 && noRowsMessage && (
        <Box className={classes.noRows}>{noRowsMessage}</Box>
      )}
    </Box>
  );
};
